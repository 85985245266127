/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --portal-theme-primary: #e4c073;
  --portal-theme-secondary: #e4c07310;
  --text-primary-color: white;
  --text-secondary-color: rgb(150, 150, 150);
  --background-primary-color: black;
  --background-secondary-color: black;
  --sidebars-text-color: white;
  --sidebars-background-color: #1d1c1d;
  --popup-background-color: #000000;
  --popup-text-color: white;
  --input-text-color: #fff;
  --input-placeholder-color: #fff;
  --input-background-primary: #fff;
  --input-background-secondary: #fff;
  --button-background-color: #1c1c1c;
  --audio-color: "#1d1c1d";
  --calendar-selected-box: #8b8b8b;
  --input-border-color: rgba(145, 158, 171, 0.32);
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--background-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.w-85 {
  width: 85%;
}

/* theme conversion */
.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}

.css-ydetsg-MuiButtonBase-root-MuiListItemButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary) !important;
}

.css-1pmf9hz-MuiButtonBase-root-MuiListItemButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary) !important;
}

.css-1ozp654-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color);
}

.css-1fuveia-MuiPaper-root-MuiPopover-paper {
  background-color: var(--popup-background-color) !important;
  color: var(--input-text-color);
}

.css-1io2p6r {
  background-color: var(--sidebars-background-color) !important;
}

.css-1ti1ykt-MuiPaper-root-MuiPopover-paper,
.css-oau2hf-MuiPaper-root {
  background-color: var(--popup-background-color);
  color: var(--input-text-color);
}

.css-dyt4qk-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  background-color: transparent;
  color: var(--portal-theme-primary);
}

.css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input,
.css-alxk9h-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--input-text-color);
}

.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root {
  color: var(--input-text-color);
}

.css-1gf7s20-MuiTableCell-root,
.css-qerafn-MuiTablePagination-root,
.css-1eivsbx-MuiInputBase-input-MuiOutlinedInput-input,
.css-ia64ha-MuiTableCell-root,
.css-66mibm-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active {
  color: var(--input-text-color);
}

.css-n1f7dt-MuiPaper-root-MuiCard-root {
  background-color: var(--sidebars-background-color);
}

.css-13isf56-MuiInputBase-input-MuiOutlinedInput-input,
.css-rxrcxl-MuiFormLabel-root-MuiInputLabel-root,
.css-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-40mfy8-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--input-text-color);
}

.css-17mra34-MuiPaper-root-MuiCard-root {
  background-color: var(--background-primary-color) !important;
  color: var(--input-text-color);
}

.css-e9crry-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color);
}

.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary);
}

.css-105feo8-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: var(--portal-theme-primary);
}

.css-1nipdsk {
  background-color: var(--background-primary-color) !important;
}

.css-9dfmxx,
.css-1al2aw7,
.css-6lzitz-MuiPaper-root-MuiDrawer-paper {
  background-color: var(--sidebars-background-color);
}

.css-qv3jka,
.css-qv3jka.Mui-active,
.css-193pwxe,
.css-1ggbx3n,
.css-1f4e4r3,
.css-sjdedl,
.css-yzm7vx,
.css-1nuss9t,
.css-184p1yy,
.css-zuwxiq,
.css-2300kw,
.css-1cgzym2-MuiButtonBase-root-MuiListItemButton-root,
.css-xgaf66,
.css-muvvuh,
.css-34c9f6,
.css-12legb5,
.css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input,
.css-1xjb83h,
.css-12ff9sa,
.css-m4fa1o-MuiButtonBase-root-MuiMenuItem-root,
.css-n1zwny {
  color: var(--input-text-color);
}

.css-bgcvkz,
.css-1clbjpy,
.css-1d9xd7g-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color);
}

.css-1c1nuln,
.css-f0u5qy,
.css-87t4q7,
.css-1g7soi9 {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-13pz97u {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-1ft4iha,
.css-1qzvkqg-MuiTypography-root-MuiDialogTitle-root {
  color: var(--portal-theme-primary) !important;
}
.css-12ff9sa {
  color: rgb(99, 115, 129);
}

/* calendar hover color */
.css-14ewge7.Mui-selected:hover,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-14ewge7.Mui-selected,
.css-2tnm32.Mui-selected,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover,
.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-2tnm32.Mui-selected:hover {
  background-color: var(--portal-theme-primary) !important;
}

/* calendar icon color */
.css-105tfim,
.css-1e799a4-MuiListItemIcon-root,
.css-1g0vwx4 {
  color: var(--portal-theme-primary);
}

/* sidebar icon */
.css-1gxhmaf-MuiListItemIcon-root,
.css-r13mc8 {
  color: var(--portal-theme-primary) !important;
}

/* toggle button menu sidebar */
.css-ze1xs7,
.css-x6t0nh-MuiButtonBase-root-MuiIconButton-root {
  color: var(--portal-theme-primary) !important;
}

/* auto complete popup background */
.css-kd0yc-MuiPaper-root-MuiAutocomplete-paper {
  background-color: var(--background-secondary-color);
  color: var(--input-text-color);
}

.css-76topu-MuiPaper-root-MuiDrawer-paper,
.css-340559 {
  background-color: var(--sidebars-background-color);
}

.css-1yv00nn-MuiPaper-root-MuiAppBar-root {
  background-color: var(--background-primary-color) !important;
}

/* chip color */
.css-1hmpryx-MuiChip-root,
.css-11d8mk9 {
  color: var(--input-text-color);
}

.css-1v6giby {
  background-color: var(--background-primary-color);
  color: var(--input-text-color);
}

.css-13d5z5v:hover {
  background-color: var(--portal-theme-primary);
}

.css-1uc7r6i {
  background-color: var(--sidebars-background-color);
}
.center-three-dots .remove-border {
  justify-content: start !important;
}

.css-7nuun {
  color: var(--input-text-color) !important;
}

.card {
  background-color: var(--background-secondary-color);
}
.new-chat-button {
  background-color: var(--portal-theme-primary);
  color: #fff;
  margin-bottom: 50px;
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  border: none;
}

.new-chat-button svg {
  color: #fff;
  fill: #fff;
}

.whatsapp-chat .new-chat-button {
  margin-bottom: 0px;
}
.event-chat-send-button {
  padding: 9px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.event-chat-emoji-picker-button {
  padding: 6px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
  width: 35px;
}

.emoji-picker-button-creat-post {
  position: absolute;
  top: 0;
  right: 0;
}

.emoji-picker-popup {
  width: fit-content;
}

.emoji-picker-popup em-emoji-picker {
  height: 200px;
}

.live-comments {
  position: relative;
}

.bottom-add-comment {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--background-secondary-color);
  z-index: 999;
}

.event-chat-send-button {
  padding: 9px;
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}
.event-chat-send-button img {
  width: 17px;
  height: 17px;
}
.event-chat-upload-button label img {
  width: 15px;
  height: 15px;
}
.event-chat-upload-button label {
  text-align: center !important;
  background-color: var(--portal-theme-secondary);
  padding: 10px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-event-comment-text-field .inception-input {
  position: relative;
}

.comment-cancel-update {
  position: absolute;
  top: 0px;
  right: -5px;
  height: 20px;
  width: 20px;
}
.clear-both {
  clear: both;
}

.live-comments-list {
  /* height: 250px !important; */
  overflow-y: scroll;
}
.new-community-page .current-events p,
.new-community-page .upcoming-events p {
  color: var(--text-secondary-color);
  font-size: 13px;
}
.inception .post-description span {
  font-size: 14px !important;
}
.chat-image-preview img {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  margin-right: 10px;
  display: inline;
}
.user-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  border: 1px solid var(--portal-theme-secondary);
}
.dynamite-level-image {
  height: 30px !important;
  margin-top: -4px;
  padding-left: 6px;
  padding-top: 3px;
  width: 34px !important;
}
.user-search-input:focus {
  outline: none;
}

.user-search-box {
  position: relative;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.inception .date-color {
  font-size: 9px !important;
  font-weight: 400 !important;
  padding-top: 5px;
}
.inception .affirmation-dots .remove-border {
  top: 2px;
  right: 0px;
  width: 20px;
  height: 20px;
}

.inception .affirmation-dots .remove-border svg {
  font-size: 14px !important;
}

.comment-cancel-update svg {
  width: 15px;
}

.event-chat-emoji-picker-button img {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

/* theme conversion ends */
/* search bar border */
/* .css-olh58z-MuiInputBase-root-MuiOutlinedInput-root {
  border: 2px solid var(--portal-theme-primary);
} */
.css-olh58z-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused,
.css-1sdq0pn.Mui-focused {
  border: 1px solid var(--portal-theme-primary);
}

/* search bar border ends */
h1 {
  font-size: 40px;
  color: var(--portal-theme-primary);
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem !important;
}
.dialog-box {
  background-color: var(--sidebars-background-color);
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}

.MuiDrawer-paperAnchorRight {
  max-width: 100% !important;
}

.css-733kmc-MuiStack-root {
  background-color: var(--sidebars-background-color);
}

.new-memories {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}

.simplebar-content-wrapper {
  overflow: scroll;
  background-color: var(--sidebars-background-color);
}
.headingMain {
  color: var(--portal-theme-primary);
}
.css-64tgzl,
.css-64tgzl .MuiChip-deleteIcon {
  color: var(--input-text-color);
}

.css-1av5wmn-MuiButtonBase-root-MuiChip-root,
.css-1av5wmn-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  color: var(--input-text-color);
}

.css-jjtu05 {
  background-color: var(--sidebars-background-color);
}

/* .forms-drawer {
  z-index: 1302 !important;
} */

.forms-drawer .simplebar-wrapper {
  height: 100% !important;
  padding-bottom: 20px;
}
.alert {
  width: 10px;
  height: 10px;
  border: #000000;
}

.css-4cxmk4,
.css-admp4s-MuiButtonBase-root-MuiIconButton-root {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}

.capitalaize {
  text-transform: capitalize;
}

.set-heading {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  margin-left: 10px;
}

.new-memories .MuiFormControl-root {
  width: 100%;
}

.view {
  color: var(--portal-theme-primary);
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}
.container-height {
  height: 93vh;
}
.inputs-fields {
  background-color: var(--input-background-primary);
}

.download-icon {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: end;
  position: absolute;
  bottom: 0px;
  left: -15px;
}

.container {
  position: relative;
}

.container h2,
.event-title h6 {
  color: var(--portal-theme-primary);
  font-size: 20px;
}
.lead-center {
  color: var(--portal-theme-primary);
}
.event-details {
  color: #fff;
}

.normal-text {
  font-size: 14px;
  color: white;
}
.normal-text a {
  color: var(--portal-theme-primary);
  font-weight: 500;
}
.save-button {
  background: linear-gradient(0.316turn, #1e1e1e, #1a1c17);
  border: 1px solid rgba(181, 157, 78, 0.81);
}
.css-1e0vrmd {
  background-color: var(--sidebars-background-color);
  color: var(--portal-theme-primary);
}

.css-1jcqjt0-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: var(--sidebars-background-color);
  /* color: var(--input-text-color); */
}

a {
  text-decoration: none !important;
  /* color: var(--portal-theme-primary) !important; */
}

a:hover {
  text-decoration: none !important;
  color: var(--portal-theme-primary) !important;
}

.menuActive {
  color: var(--portal-theme-primary) !important;
  background-color: var(--portal-theme-secondary) !important;
}

.a-tag-color {
  color: grey;
}

/* a:active {
  text-decoration: none !important;
  color: var(--portal-theme-primary) !important;
} */
/* delete Icon color in edit recording */
.icon-color {
  color: var(--portal-theme-primary);
  cursor: pointer;
}

/* delete Icon color in edit recording end */

legend {
  float: unset;
}

.text-area-task {
  background-color: var(--input-field-backGround);
  outline: none;
}

.programm-card h3 {
  color: var(--portal-theme-primary);
}

.programm-card p span {
  color: var(--text-light);
}

.lesson-card h4 {
  color: var(--portal-theme-primary);
}

/* dashboard layout  */
.css-n1f7dt-MuiPaper-root-MuiCard-root,
.css-15ozkn2-MuiPaper-root,
.css-1v9x5yf-MuiPaper-root-MuiCard-root,
.css-95fqk8-MuiPaper-root-MuiCard-root,
.css-1bolyt8-MuiPaper-root-MuiCard-root,
.css-mzqo5x-MuiPaper-root-MuiCard-root,
.apexcharts-legend-text,
.css-zeed5b-MuiTypography-root,
.css-1p235ip-MuiTypography-root,
.css-9dfmxx,
.css-rm0nnh,
.css-ecazo7,
.css-5bubsz-MuiTypography-root,
.css-rq00hd-MuiTypography-root,
.css-5apxr0,
.css-1fopwxw,
.css-12hbtlu,
.css-1nyw7kk,
.css-3wu190,
.css-24mna3 {
  color: var(--input-text-color) !important;
}

/* dashboard layout end */
/* audio player color */
audio::-webkit-media-controls-panel {
  background-color: var(--audio-color) !important;
  border-radius: 50px;
}

::-webkit-media-controls-time-remaining-display,
audio::-webkit-media-controls-current-time-display {
  color: var(--portal-theme-primary);
}

::-webkit-media-controls-enclosure {
  background-color: var(--portal-theme-secondary);
}

/* audio player color end */

.simplebar-wrapper {
  height: 400px;
  overflow: hidden;
}

.simplebar-content-wrapper {
  overflow: scroll;
}

.support_notification_sidebar {
  height: 15px;
  margin-left: 40px;
  color: #31a24c;
  fill: #31a24c;
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

textarea:hover {
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
}

.programme-card-desc {
  margin-top: 0px !important;
  font-size: 14px;
}

.programme-duration {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.circle-image {
  padding: 0;
}

.suppor-ticket-data-table .MuiDataGrid-virtualScrollerContent {
  min-height: 200px !important;
}

.lesson-notes-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-light);
}

.css-1tc40pv,
.css-1vbggne-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: var(--input-text-color);
}

.text-size {
  font-size: small;
}

/* wheel of life  */
.top-vedio-container {
  /* / padding-top: 145px; / */
  border-radius: 10px;
  /* background-image: url("/images/Rectangle.jpg"); */
  background-size: cover;
  background-position: center;
}

.attach-with-iframe {
  margin-right: -20px;
}
.top-vedio-container .wrapper .player iframe {
  position: absolute;
  top: 0px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(181, 157, 78);
}

.portal-card-linear {
  background: linear-gradient(
      89.74deg,
      #151717 0.23%,
      rgba(21, 23, 23, 0) 75.85%
    ),
    radial-gradient(
      75.75% 168.25% at 100% 84.87%,
      rgba(181, 157, 78, 0.2) 0%,
      rgba(21, 23, 23, 0) 100%
    );
  border-radius: 0px 24.4131px 24.4131px 0px;
  padding: 40px 40px;
  height: auto;
  font-size: 16px;
}

.portal-card-linear h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.portal-card-linear p {
  color: #ccc9c4;
  font-weight: 400;
}

.question-description-top-box p {
  font-size: 1rem;
}

.question-answer-one {
  height: 40px;
  width: 100%;
}

.button-link a {
  color: var(--portal-theme-primary);
}

.btn-btn-portal-navigate {
  cursor: pointer;
}

.top-banner-section {
  /* / background: #f6f7f9; /
  / background: black; / */
  /* background-image: url("/images/Questionpagebg.png") */
  background-position: center;
  background-size: cover;
}

.accordion-summary svg {
  color: var(--portal-theme-primary);
}

.qustion-section-summary {
  color: var(--input-text-color);
}

.question-answer {
  padding: 11px;
}
.profile-space {
  margin-bottom: -33px;
}
.back-buuton-profile {
  margin-top: 10px;
  margin-right: 7px;
}

.question-answer:hover {
  background-color: var(--portal-theme-secondary);
}
.question-answer-new:hover {
  background-color: var(--portal-theme-secondary);
}

/* .general-question-answer {
  padding: 11px;
} */

.selectedAnswer {
  background-color: var(--portal-theme-secondary);
}

.black-btn {
  background-color: #ccb668;
  color: black;
  padding: 10px 55px;
  font-weight: 500;
  border: none;
  border-radius: 50px;
}
.top-banner-section {
  /* / background: #f6f7f9; /
  / background: black; / */
  /* background-image: url("/images/Questionpagebg.png") */
  background-position: center;
  background-size: cover;
}
.scale-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.scale-index {
  margin-top: 5px;
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 500;
  border-top: solid 1px #cea25450;
  border-bottom: solid 1px #cea25450;
  cursor: pointer;
}
.chat-icon-container {
  display: flex;
  justify-content: end;
  align-items: center;
}
.scale-index:hover {
  background-color: #cea254;
}

.scale-border-left {
  border-left: solid 1px #cea25450;
}

.scale-border-right {
  border-right: solid 1px #cea25450;
}

.take-assessment-wrapper {
  min-height: calc(100vh - 110px);
}

.position-centered-rel {
  position: relative;
}
.gained-cores h2 {
  color: var(--portal-theme-primary) !important;
}

.legend-title .color-box {
  height: 14px;
  width: 10%;
  margin-top: 3px;
  margin-right: 15px;
}

.legend-title .description-box {
  width: 90%;
  /* font-size: 15px; */
}
.assessment-text-area {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 4px;
  background-color: var(--sidebars-background-color);
  font-size: 16px;
  resize: none;
  color: white;
}
.portal-banner {
  padding: 60px 0 80px;
}

.portal-card-linear {
  background: linear-gradient(
      89.74deg,
      #151717 0.23%,
      rgba(21, 23, 23, 0) 75.85%
    ),
    radial-gradient(
      75.75% 168.25% at 100% 84.87%,
      rgba(181, 157, 78, 0.2) 0%,
      rgba(21, 23, 23, 0) 100%
    );
  border-radius: 0px 24.4131px 24.4131px 0px;
  padding: 40px 40px;
  height: auto;
  font-size: 16px;
}

.portal-card-linear h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.portal-card-linear p {
  color: #ccc9c4;
  font-weight: 400;
}

.video-portal-border {
  border-radius: 10px;
  border-width: 2px;
  padding: 2px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(181, 157, 78, 0.5)
  );
}

.video-portal-border iframe {
  border-radius: 10px;
  width: 100%;
  background: #000000;
}
.btn-btn-portal-navigate {
  cursor: pointer;
}
.btn-btn-portal {
  padding: 14px 35px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
  transition: 0.5s;
  background: #b59d4e;
  color: #000000;
  border: 1px solid #b59d4e;
}
.btn-portal button {
  background: #b59d4e;
  color: #151717;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 30px;
  position: absolute;
  bottom: -25px;
}

.btn-btn-portal:hover {
  color: #b59d4e;
  border: 1px solid #515151;
  background: transparent;
}

.portal-wrapper {
  padding: 30px 0;
}

.portal-wrapper h2,
.services-testimonial-wrapper h2 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px !important;
  color: #fff !important;
  line-height: 1.1;
}

.portal-member h2 {
  line-height: 1.3;
}

.portal-card {
  background: linear-gradient(113.76deg, rgba(0, 0, 0, 0) 0%, #151a21 100%);
  border: 1px solid rgba(181, 157, 78, 0.5);
  padding: 20px 20px 25px;
  border-radius: 6px;
}

.bg-card-black {
  background: #1e2020;
  border-radius: 12px;
  padding: 25px 20px;
}

.bg-card-yellow {
  background: #b59d4e;
  border-radius: 12px;
  padding: 25px 20px;
  color: #151717;
}

.bg-card-black h5 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}

.bg-card-black h5 > span {
  font-size: 14px;
  font-weight: 500;
  color: #ccc9c4;
}

.bg-card-black h6 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  margin-bottom: 30px;
}

.bg-card-black h6:before {
  content: "";
  width: 15%;
  height: 1.2px;
  position: absolute;
  top: 25px;
  transform: rotate(360deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #b59d4e,
    rgba(0, 0, 0, 0)
  );
}

.bg-card-yellow h5 {
  color: #151717;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.bg-card-yellow h5 > span {
  font-size: 14px;
  font-weight: 500;
  color: #151717;
}

.bg-card-yellow h6 {
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: start;
  justify-content: start;
  position: relative;
  margin-bottom: 30px;
}

.bg-card-yellow h6:before {
  content: "";
  width: 15%;
  height: 1.2px;
  position: absolute;
  top: 25px;
  transform: rotate(360deg);
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    #000000,
    rgba(0, 0, 0, 0)
  );
}

.date-Container {
  padding: 2px 30px 20px 30px;
  display: flex;
  justify-content: space-between;
}

.avtar-setting {
  width: 35%;
  justify-content: space-between;
}
.date-setting {
  padding-top: 5px;
}

.folder-avtar-setting-container {
  width: 55%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 525px) {
  .portal-card {
    padding: 20px 20px 35px;
  }
  .date-Container {
    flex-direction: column;
    padding: 0;
  }
  .avtar-setting {
    width: 100%;
  }
  .folder-avtar-setting-container {
    width: 100%;
  }
  .product-name-button {
    margin-top: 10px;
  }
}

.portal-banner {
  padding: 60px 0 80px;
}

.portal-card-linear {
  background: linear-gradient(
      89.74deg,
      #151717 0.23%,
      rgba(21, 23, 23, 0) 75.85%
    ),
    radial-gradient(
      75.75% 168.25% at 100% 84.87%,
      rgba(181, 157, 78, 0.2) 0%,
      rgba(21, 23, 23, 0) 100%
    );
  border-radius: 0px 24.4131px 24.4131px 0px;
  padding: 40px 40px;
  height: auto;
  font-size: 16px;
}

.portal-card-linear h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 15px;
}

.portal-card-linear p {
  color: #ccc9c4;
  font-weight: 400;
}

.video-portal-border {
  border-radius: 10px;
  border-width: 2px;
  padding: 2px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(181, 157, 78, 0.5)
  );
}

.video-portal-border iframe {
  border-radius: 10px;
  width: 100%;
  background: #000000;
}

.home-page-plan ul {
  list-style-type: none;
  padding-left: 0;
}

.home-page-plan ul li {
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 22px;
}

.home-page-plan ul .tick {
  background: url(../images/Vector-tick.png) no-repeat left 4px;
}

.home-page-plan ul .cross {
  background: url(../images/cross-plan-icon.png) no-repeat left 5px;
}

.habit-days ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 10px;
}

.habit-days ul li {
  display: inline-block;
  margin-right: 20px;
  width: 25px;
  height: 30px;
  text-align: center;
  padding-top: 5px;
  background-color: var(--portal-theme-secondary);
  border-radius: 3px;
}
.MuiOutlinedInput-root .MuiAutocomplete-input {
  color: white !important;
  padding: 7.5px 4px 7.5px 6px;
}

.habits-slider .MuiSlider-markLabel {
  left: 97%;
  font-size: 16px;
}

.habits-slider .MuiSlider-valueLabel {
  font-size: 16px;
}

.silder-value {
  padding-top: 5px;
  margin-right: 10px;
}

.habits-gray-description {
  color: var(--text-secondary-color);
  font-weight: normal;
}

.frequency-labels .MuiCheckbox-colorPrimary {
  padding: 5px;
}

.frequency,
.habit-reminder {
  padding-left: 10px;
}

.frequency p,
.habit-reminder p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

/* wheel of life end */

/* Support ticket chips on auto reply  */
.css-6bc6cn-MuiChip-root,
.css-17svla2-MuiButtonBase-root-MuiChip-root,
.css-1egl4wx {
  color: var(--input-text-color);
}

/* .css-i4bv87-MuiSvgIcon-root {
  fill: var(--portal-theme-primary);
} */

.css-wj2255-MuiButtonBase-root-MuiButton-root:hover,
.css-wj2255-MuiButtonBase-root-MuiButton-root {
  background-color: #ffc107d9;
}

.css-40jehe:hover {
  background-color: var(--portal-theme-primary);
}
.counter {
  display: flex;
  margin-left: 28px;
  margin-top: 28px;
}
.tracking_code_heading {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 7px;
  margin-left: 4px;
}
.textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}
.goal-gallery {
  height: 200px;
  background-color: grey;
  border-radius: 10px;
}
.dontShow {
  display: none;
}
.show {
  display: block;
}
.anchor-style {
  text-decoration: none;
  cursor: default;
  color: var(--input-text-color);
}

.anchor-style-color {
  text-decoration: none;
  cursor: pointer;
  color: var(--portal-theme-primary);
}

/* auto complete text inside color */
.time-zone-box input {
  color: white;
}

.goal-statement-messages {
  color: white;
}

.description-color {
  color: var(--portal-theme-primary) !important;
}

.programme-content {
  padding: 15px 15px 20px 15px;
}
.mini-cards {
  position: relative;
  width: 100%;
  align-items: center;
  border-radius: 6px;
  /* background: #141717; */
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: white;
  font-size: 15px;
  margin-top: 15px;
  border: 1px solid rgb(71 66 34 / 66%);
}

.parent-container {
  height: 230px;
  overflow: auto;
}
.parent-container-notes {
  height: 80vh;
  overflow: auto;
}
.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.centered-chat-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.mini-cards h4 {
  font-weight: 400 !important;
}
.diary-font-weight {
  font-weight: 400 !important;
  justify-content: space-between;
}
.diary-font-weight h4 {
  font-weight: 400 !important;
}
.menu-container .mx-3 {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}
.for-fix {
  position: fixed;
  right: 82px;
  top: 16px;
  z-index: 12;
}
.set-manu-position {
  position: absolute;
  top: 0px;
  right: 10px;
}
.mui-btn-close-icon {
  position: absolute;
  right: 3px;
  top: 3px;
}

#fixedbutton {
  position: fixed;
  bottom: 30px;
  right: 27px;
  z-index: 99;
}

.lesson-content {
  padding: 15px 15px 20px 15px;
}

.hovr-effect:hover {
  color: black;
}

/* .small-contained-button {
  background-color: var(--portal-theme-primary);
  padding: 10px 16px;
  color: #fff;
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
} */
.small-contained-button {
  background-color: var(--button-background-color) !important;
  padding: 8px 16px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
}

.small-contained-chip-success {
  background-color: #2e7d32;
  padding: 1px 6px;
  color: #fff;
  border-radius: 20px;
  border: 1px solid #2e7d32;
  font-size: 10px;
}

.small-contained-chip-error {
  background-color: #ff4842;
  padding: 1px 6px;
  color: white;
  border-radius: 20px;
  border: 1px solid #ff4842;
  font-size: 10px;
}

.small-outlined-button {
  background-color: #fff;
  padding: 8px 16px;
  color: var(--portal-theme-primary);
  border-radius: 5px;
  border: 1px solid var(--portal-theme-primary);
}

.form-label-lesson {
  font-weight: bold;
}

.feedback-programme {
  font-size: 25px;
}

.main-heading-community {
  color: var(--input-text-color) !important;
}

.normal-font {
  font-size: 14px;
  font-weight: 500;
}

.feedback-programme-header {
  display: flex;
  justify-content: center;
}

.feedback-programme-text {
  padding: 10px;
  font-weight: bold;
  margin-bottom: 0;
}

.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.suppor-ticket-data-table .MuiDataGrid-row {
  cursor: pointer;
}

.suppor-ticket-data-table .MuiIconButton-sizeSmall {
  display: none;
}

.suppor-ticket-data-table .MuiDataGrid-cell:focus,
.suppor-ticket-data-table .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.suppor-ticket-data-table .MuiDataGrid-columnHeaders {
  border: 2px solid var(--portal-theme-secondary) !important;
  color: var(--portal-theme-primary) !important;
}

.suppor-ticket-data-table {
  border: 2px solid var(--portal-theme-secondary) !important;
}

.suppor-ticket-data-table .MuiDataGrid-iconSeparator {
  cursor: pointer;
}

.bold-heading {
  font-weight: bold;
}

.section-space {
  margin-top: 0.75rem !important;
  color: var(--input-text-color) !important;
}
.section-space-support-ticket-setail {
  margin-top: 0.15rem !important;
  color: var(--input-text-color) !important;
}
.detials-date-container {
  margin-top: 10px;
}

.set-display-inline .remove-border,
.affirmation-dots .remove-border {
  display: inline;
  position: absolute;
  top: 5px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.Ninty-Day-trackerList-dots .remove-border {
  display: inline;
  position: absolute;
  top: 60px;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}

.submit-button {
  text-align: center;
  /* background-image: url(../images/btn-img.png); */
  background-color: var(--button-background-color);
  border: 1px solid var(--portal-theme-primary);
  color: var(--portal-theme-primary);

  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.css-1tir071-MuiFormLabel-root-MuiInputLabel-root,
.css-7rzmxr-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--input-text-color);
}

.rating-stars {
  float: right;
  padding-bottom: 15px;
  padding-right: 15px;
}

.rating-stars-place {
  position: absolute;
  bottom: 0px;
  right: 8px;
}

.program-font-size {
  font-size: 14px;
  font-weight: 500;
}

.heading-subtitle {
  font-size: 14px;
  font-weight: 700;
}

.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.no-access-string p {
  margin-bottom: 0 !important;
}

.calender-events-text {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  color: white;
}

.calender-events-box {
  margin-top: 30px;
}

/* .fc .fc-daygrid-day-bottom {
  margin-top: 20px !important;
} */

.fc-daygrid-more-link {
  text-decoration: underline;
}
.full-calendar .fc-more-popover {
  background-color: #8b8b8b;
  background-color: var(--calendar-selected-box);
  z-index: 1;
}

.icon-style {
  /* color: var(--text-light); */
  color: white;
  float: right;
  padding-right: 4px;
  padding-top: 5px;
}

.icon-style:hover {
  /* color: var(--portal-theme-primary); */
  border-radius: 50%;
}

/* 
.icon-button-style{
    float: right;
} */

.lesson-card {
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
}

.back-screen-button {
  background-color: var(--portal-theme-secondary);
  margin-bottom: 0px !important;
}

.media-margin {
  margin-top: 0.75rem !important;
}

.back-arrow-margin {
  margin-bottom: 10px;
}

.css-19kzrtu {
  padding: 0px 0px;
}

.vault-category {
  border: 1px solid var(--portal-theme-secondary);
  /* background-color: var(--portal-theme-secondary); */
  padding: 8px 16px;
  color: var(--portal-theme-secondary);
  font-weight: bold;
  border-radius: 5px;
}

/* table border color */
.css-5hc1ig-MuiTableCell-root,
.css-hbbyn8 {
  border-bottom: 1px solid grey;
}

/* table border color end */

.memories-date p {
  color: var(--text-light);
  margin-top: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: var(--back-arrow) !important;
}

.carousel-nav-button {
  background-color: var(--text-light) !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 12px;
  /* padding-right: 5px; */
}

.center-text {
  /* text-align: center;
  padding: 10px;
  margin-top: 10%; */
  padding: 190px 0;
  text-align: center;
}

.Question-text {
  color: var(--input-text-color) !important;
}

.css-1uc7r6i {
  color: var(--input-text-color);
}

.css-vubbuv {
  fill: var(--portal-theme-primary);
}

/* goal statement */
.question-background {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
  margin-bottom: 10px;
  border-radius: 5px;
}

.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--portal-theme-primary);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}
.image-slider .image-thumbnails .is_selected {
  border: 1px solid var(--portal-theme-primary);
  padding: 3px;
}

.image-slider .image-gallery-icon svg {
  height: 40px;
  width: 40px;
  font-size: 16px;
  color: var(--portal-theme-primary);
  background-color: var(--portal-theme-secondary);
  border-radius: 50%;
}

.image-slider .image-thumbnails {
  display: flex;
  justify-content: space-between;
}

.image-slider .main-image img {
  max-height: 400px;
}
.calendar-event-title {
  color: #fff;
  margin-bottom: 5px;
  font-size: 13px;
}
.calendar-event-title:hover {
  color: var(--portal-theme-primary);
}
.fc-daygrid-day .start_time_for_popup {
  display: none;
}
.image-slider .image-thumbnails img {
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
}
.off-percentage {
  display: flex;
}

.off-percentage p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.off-percentage .plus-icons {
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-primary);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 10px;
}

.shop-item-rating {
  display: flex;
}

.shop-item-rating p {
  margin-bottom: 0;
  margin-top: 3px;
  margin-left: 10px;
}

.shop-modal-box-cart .item-image img {
  width: auto;
}

.total-price h4 {
  color: var(--portal-theme-primary);
}
.shop-modal-box .item-quantity,
.detail-item-quantity .item-quantity {
  display: flex;
  border: 1px solid var(--portal-theme-secondary);
  justify-content: space-between;
  padding: 0 7px;
  font-size: 20px;
  max-width: 115px;
}

.detail-item-quantity .item-quantity {
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
}
.anchor-style {
  cursor: pointer;
}
.shop-modal-box .item-quantity input,
.detail-item-quantity .item-quantity input {
  width: 60px;
  background-color: transparent;
  outline: none;
  border-left: 1px solid var(--portal-theme-secondary);
  border-right: 1px solid var(--portal-theme-secondary);
  border-bottom: 0;
  color: var(--input-text-color);
  font-size: 16px;
  text-align: center;
}

.detail-item-quantity .item-quantity input {
  border-left: 1px solid var(--portal-theme-primary);
  border-right: 1px solid var(--portal-theme-primary);
}

.shop-modal-box .item-quantity span,
.detail-item-quantity .item-quantity span {
  cursor: pointer;
}
.shop-detail-price {
  font-size: 30px;
}
.shop-modal-box .item-image img {
  width: 60px;
  border-radius: 10px;
}
.shop-modal-box-cart .item-image img {
  width: auto;
}

element.style {
}
.shop-modal-box .single-cross-button span {
  background-color: var(--portal-theme-secondary);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: block;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  color: var(--portal-theme-primary);
}
.add-to-cart-button svg {
  margin-right: 10px;
  font-size: 20px;
}

.css-1mav5hp-MuiPaper-root-MuiDrawer-paper {
  color: var(--input-text-color);
}

@media only screen and (max-width: 375px) {
  .lesson-heading {
    padding-top: 10px;
  }

  .card-spacing {
    margin-top: 0.75rem;
  }

  .mobile-margin {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  /* .lesson-card-image{
        height: 100%;
    } */
}

/*=================================  full calender  =======================*/
.css-15ynx34,
.css-1cwi9p6-MuiPaper-root,
.css-yuj0vc-MuiTypography-root,
.css-1ri3awb {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.css-2tnm32,
.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root {
  background-color: transparent !important;
  color: var(--input-text-color);
}

.css-s80gtz-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: var(--input-text-color);
  background-color: var(--portal-theme-primary) !important;
}

.css-rbocvz-MuiButtonBase-root-MuiPickersDay-root {
  background-color: var(--sidebars-background-color) !important;
  color: var(--input-text-color) !important;
}

.calender-events-box {
  color: var(--white);
  /* padding: 20px; */
}

.fc-daygrid-day-frame {
  overflow: hidden;
}

.full-calendar .fc-day-today {
  background-color: #263048 !important;
}

.fc-timegrid-event {
  background: none !important;
  border: none !important;
}

/* .calender-events-text {
  margin-left: 5px;
  padding: 15px;
  background-color: var(--portal-theme-primary);
} */

.fc-toolbar-chunk {
  display: inline-flex;
}

/* .fc{
  padding: 10px;
    border-top-style: solid;
    border-color: var(--portal-theme-primary);
} */

.eventimage {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  border: solid;
  border-color: #42969c;
}

.fc-daygrid-event {
  border: none !important;
  background: none !important;
}

.fc-today-button {
  background: none !important;
  color: var(--text-primary-color) !important;
  border-style: none !important;
  font-weight: bold !important;
}

.fc-right {
  background: none !important;
  color: black !important;
  border-style: none !important;
}

.fc-prev-button {
  background: none !important;
  color: var(--portal-theme-primary) !important;
  border-style: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-right {
  background: none !important;
  color: black !important;
  border-style: none !important;
}

.fc-next-button {
  background: none !important;
  color: var(--portal-theme-primary) !important;
  border-style: none !important;
  border: 1px solid var(--portal-theme-primary) !important;
}

.fc-button-active,
.fc-more-link {
  color: var(--portal-theme-primary) !important;
  color: var(--portal-theme-primary) !important;
  text-decoration: underline !important;
}
.color {
  color: var(--portal-theme-primary);
  font-size: 14px;
}
.custom-popover {
  overflow: auto;
  overflow-x: hidden;
}
.text-area-container .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid var(--portal-theme-primary);
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.menu-dropdown-icon svg {
  float: right !important;
  margin-left: 15px;
}
.text-area-container .form-control:focus {
  box-shadow: none;
  border-color: var(--portal-theme-primary);
  background-color: transparent;
  color: white;
}
.css-1lenskg-MuiButtonBase-root-MuiMenuItem-root {
  color: var(--input-text-color);
}

.fc-dayGridMonth-button {
  background: none !important;
  /* color: var(--input-text-color) !important; */
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
}

.link-anchor a {
  color: var(--portal-theme-primary) !important;
}

.fc-timeGridWeek-button {
  background: none !important;
  /* color: var(--input-text-color) !important; */
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
}

.fc .fc-button-primary {
  color: var(--input-text-color);
}

.fc .fc-button-primary:hover {
  color: var(--portal-theme-primary);
}

.fc-timeGridDay-button {
  background: none !important;
  /* color: var(--input-text-color) !important; */
  border-style: solid !important;
  border-color: var(--portal-theme-primary) !important;
}

/* .fc-col-header{
  border-bottom-style: solid !important;
}  */

.fc-col-header-cell-cushion {
  color: var(--portal-theme-primary) !important;
  text-decoration: none;
}

.fc-scrollgrid-section {
  border-style: none;
}

/* .fc-day-sun {
  border-bottom:thick !important;
    border-right-style: hidden !important;
    padding: 10px;
} 

.fc-day-mon {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-tue {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-wed {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-thu {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-fri {
  border-bottom: thick !important;
    border-right-style: hidden !important;
} 

.fc-day-sat {
  border-bottom: thick !important;
    border-right-style: hidden !important;
}  */

.calender-title {
  color: #fff;
}

.event-title-dot {
  height: 11px;
  width: 11px;
  margin-top: 4px;
  margin-right: 3px;
  border-radius: 50%;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: var(--input-text-color);
  font-weight: bold;
}

.fc-daygrid-day-top {
  float: left !important;
}

.fc-scrollgrid-sync-table {
  border-top: thick !important;
}

.dropdownbutton {
  border-color: #ccc5c5 !important;
  font-size: 12px !important;
}

.dialog-img {
  border: none;
  z-index: 1;
  top: 60%;
  left: "center";
}

.dialog-image {
  width: 400px;
  height: 400px;
}

.dialog-detail {
  border-color: #42969c;
  border-radius: 5px;
  position: static;
  z-index: 1;
  top: 100%;
  left: 20%;
  width: 400px;
}

.perform-action-on {
  min-width: 400px;
  max-width: 100%;
}

.perform-action-on label {
  color: #fff;
}

.log-detail {
  width: 120px;
}

.social-set {
  border: solid;
  border-color: #ccc5c5;
  border-radius: 50px;
  display: flex;
}

.dl-button {
  /* border: thin; */
  border: 1px solid #ccc5c5;
  /* border-color: #ccc5c5; */
  padding: 5px;
  display: flex;
}

.dl-button2 {
  /* border: thin; */
  border: 1px solid black;
  /* border-color: #42969c; */
  padding: 5px;
  display: flex;
}

.full-calendar-new-flow .fc-header-toolbar {
  display: none;
}

.toollbar-group-buttons .small-contained-button {
  border-radius: 0;
  color: #fff;
  outline: none !important;
  background-color: transparent !important;
  padding: 0.4em 0.65em;
}

.toollbar-group-buttons .small-contained-button svg {
  color: #fff !important;
  font-size: 16px;
}

.toollbar-group-buttons .small-contained-button:first-child {
  border-radius: 5px 0 0 5px;
}
.toollbar-group-buttons .small-contained-button:last-child {
  border-radius: 0 5px 5px 0;
}
.toollbar-group-buttons .selected-button {
  color: var(--portal-theme-primary) !important;
}
.full-calendar-toolbar .today-text {
  opacity: 0.65;
  margin-right: 16px;
}

/* ====================================== for ipad screen ============================== */

/* @media only screen and (max-width: 768px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */
/* ====================================== for iphone screen ============================== */

/* @media only screen and (max-width: 375px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */

/* pinterest layout design */

.profile-details {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}

.profile-details-padding {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.profile-heading {
  font-size: 30px;
  padding-left: 35px;
}

.add-category-button {
  margin-right: 44px;
  margin-bottom: 15px;
}

/* pinterest */
/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
  } */
/* h1 {
    color: coral;
  } */
.quotes-heading {
  margin-left: 11px;
  /* margin-bottom: 20px; */
  line-height: 1.235;
  margin-bottom: 25px;
}

.grid-container {
  columns: 3 200px;
  column-gap: 1.2rem;
  width: 95%;
  margin: 0 auto;
}

.grid-container div {
  width: 150px;
  margin: 0 1.5rem 1.5rem 0;
  display: inline-block;
  width: 99%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* padding: 0px; */
  /* box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%); */
  border-radius: 16px;
  transition: all 0.25s ease-in-out;
}

.grid-container div:hover img {
  filter: grayscale(0);
}

.grid-container div:hover {
  /* box-shadow: 5px 5px 5px rgba(117, 117, 117, 0.5); */
  cursor: pointer;
}

.grid-container div img {
  width: 100%;
  /* filter: grayscale(100%); */
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  transition: all 0.25s ease-in-out;
}

.grid-container div p {
  margin: 4px 0;
  padding: 15px 15px 20px;
  /* padding-top: 9px; */
  text-align: left;
  /* font-style: italic; */
}

/* MUI Card */
.mui-custom-card {
  background-color: var(--background-secondary-color);
}

.mui-custom-card h3 {
  color: var(--portal-theme-primary);
}

.mui-custom-card p span {
  color: var(--text-secondary-color);
}

.mui-custom-card-content {
  padding: 15px 15px 20px 15px;
}

.mui-custom-card-description {
  margin-top: 0px !important;
  font-size: 14px;
  color: var(--text-primary-color);
}

.mui-card-button {
  fill: var(--portal-theme-primary);
  top: 45%;
  left: 22%;
  font-size: 14px;
  position: absolute;
  padding: "10px 20px";
}

.wider-card .mui-card-button {
  left: 45%;
}

.mui_gray_description {
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 0px !important;
  color: var(--text-secondary-color);
}

.mui-custom-card-dots {
  z-index: 10 !important;
}

.mui-custom-card-dots svg {
  color: var(--portal-theme-primary) !important;
  fill: var(--portal-theme-primary) !important;
}

.mui-custom-card-dots .remove-border {
  display: inline;
  position: absolute;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  margin-top: 6px !important;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.menu_pod_card_icon {
  display: flex;
  justify-content: center;
  position: absolute;
  color: var(--portal-theme-secondary);
  right: 5px;
  width: 36px;
  height: 36px;
  margin-top: -10px !important;
  border-radius: 50% !important;
  background-color: var(--portal-theme-secondary) !important;
  text-align: center;
}

.menu_card_icon {
  display: inline;
  position: absolute;
  right: 16px;
  width: 36px;
  height: 36px;
  margin-top: -10px !important;
  border-radius: 50% !important;
  text-align: center;
}

.card_lower_title_description {
  margin-top: 16px !important;
  margin-bottom: 0 !important;
  color: #fff;
}

.side-image-mui-card {
  background-color: var(--background-secondary-color);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  color: var(--text-primary-color);
}

.side-image-mui-card h4 {
  color: var(--portal-theme-primary);
}

/* MUI Card Ended*/

.tutorials-list .title h2 {
  font-size: 30px;
  color: #fff;
}

/* pinterest end */
@media screen and (max-width: 1024px) {
  .quotes-heading {
    margin-left: 10px;
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 20px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 769px) {
  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .quotes-heading {
    margin-left: 7px;
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 40px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 376px) {
  .profile-button {
    margin-bottom: 20px;
    width: 150px;
    margin-left: -10px;
  }

  .profile-image {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .profile-heading {
    font-size: 30px;
    padding-left: 0px;
  }

  .profile-button-padding {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }

  .profile-details-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .quotes-heading {
    margin-left: 6px;
    margin-bottom: 40px;
  }

  .add-category-button {
    margin-right: 19px;
    margin-bottom: 15px;
  }
}

.pin-code-field swd-pin-field {
  justify-content: space-between;
}

.MuiPaper-root {
  background-color: var(--sidebars-background-color) !important;

  /* Change BG color of date picker when opens upper side*/
}

.autocomplete-select span {
  color: var(--input-text-color);
}

.question-style {
  margin: 0px !important;
}

.slider-width {
  width: 95% !important;
}

.css-1l0glfa-MuiSlider-markLabel,
.css-13o9n0h-MuiSlider-markLabel,
.css-13ikhxx {
  color: var(--input-text-color);
}

.accountability-slider .css-13o9n0h-MuiSlider-markLabel {
  color: gray;
}

.question-survey {
  margin-top: 11px;
  margin-left: 40px;
  font-size: 30px;
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--input-text-color);
  width: 100%;
  text-transform: capitalize;
}

.navbar-icon {
  color: var(--portal-theme-primary);
  margin-right: 12px;
}

.header-logo img {
  max-height: 50px;
  margin: 0 auto;
  margin-top: 10px;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/* MUI Custom Table Start*/

.table-status span {
  padding: 2px 12px;
  font-size: 0.75rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  border-radius: 8px;
}
.text-nowrap {
  white-space: nowrap !important;
}

.mui-without-bg-custom-table {
  background-color: transparent;
  border: 1px solid #fff;
}

.mui-without-bg-custom-table .MuiPaper-root,
.mui-without-bg-custom-table .Mui-selected,
.mui-without-bg-custom-table .MuiTableRow-hover:hover {
  background-color: var(--sidebars-background-color);
}

.card-with-background {
  background-color: var(--sidebars-background-color);
  border: none;
}

.card-with-background th,
.mui-without-bg-custom-table th {
  position: sticky;
  top: 0;
  z-index: 100;
}

.card-with-background th {
  background-color: var(--sidebars-background-color) !important;
}

.card-with-background .MuiPaper-root,
.card-with-background .Mui-selected,
.card-with-background .MuiTableRow-hover:hover {
  background-color: rgba(145, 158, 171, 0.08);
}

.mui-without-bg-custom-table .MuiTablePagination-root p {
  margin-bottom: 0;
}

.mui-title {
  font-size: 18px;
  color: var(--portal-theme-primary);
  margin-top: 39px;
  margin-left: 24px;
}
.mui-without-bg-custom-table .MuiCheckbox-root {
  padding: 0;
}

.mui-without-bg-custom-table .MuiPaper-elevation {
  background-color: transparent;
}

.mui-without-bg-custom-table .MuiOutlinedInput-root {
  border: 1px solid #8f8f8f;
}

.mui-without-bg-custom-table .mui-table-footer {
  display: flex;
  justify-content: space-between;
}

.mui-without-bg-custom-table .mui-table-footer .rows_selected_text {
  color: var(--portal-theme-primary);
  padding-left: 20px;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: 400;
}

.table-status .Active {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.table-status .InActive {
  color: rgb(183, 33, 54);
  background-color: rgba(255, 72, 66, 0.16);
}

.mui-table-status span {
  padding: 2px 12px;
  font-size: 0.75rem;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  border-radius: 8px;
}

.mui-table-status .Active {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.mui-table-status .InActive {
  color: rgb(183, 33, 54);
  background-color: rgba(255, 72, 66, 0.16);
}

.mui-custom-table th,
td {
  white-space: nowrap;
}

.MUI-data-table {
  background-color: var(--popup-background-color);
}

.show_link_color a {
  color: var(--portal-theme-primary);
}

/* width */
.MuiTableContainer-root::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Handle */
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  background: #636262;
  border-radius: 10px;
}

/* MUI Custom Table End*/
.sale-page-title {
  color: var(--portal-theme-primary);
  font-size: 23px;
  margin-left: 20px;
}

.content-disabled .Mui-disabled {
  cursor: not-allowed;
}

.confirmation-popup-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.6;
  padding: 16px 24px;
}

.confirmation-popup-title h2 {
  color: var(--portal-theme-primary);
  font-size: 25px;
}

.confirmation-popup-title p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0;
}

.wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.button-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.answer-ticket {
  background-color: #007bff;
  color: white;
  width: 80px;
}

.solved-ticket {
  background-color: #28a745;
  color: white;
  width: 80px;
}

.trash-ticket {
  background-color: #ff0000;
  color: white;
  width: 80px;
}

.pending-ticket {
  background-color: #ffc107d9;
  color: white;
  width: 80px;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0px;
}

/* Comunity */
.new-community-page .profile-cards {
  cursor: auto;
}

.monthly-leads {
  overflow-y: scroll;
  height: 300px;
}

.new-community-page .banner-section img {
  border-radius: 7px;
}

.banner-bg-image {
  width: 100%;
}

.new-community-page .jade-img {
  border: 4px solid #eebe60;
  border-radius: 12px;
  display: inline;
  /* max-width: 100px; */
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.zoom-password svg {
  font-size: 16px;
  margin-left: 5px;
}

.new-community-page .jade-div {
  position: absolute;
  bottom: -10px;
  left: 30px;
  background-color: black;
  border-radius: 8px;
}

.new-community-page .img-left {
  margin-left: -25px;
  padding-top: 66px;
  display: inline;
}

/* membership-section  */
.new-community-page .back-light-div {
  background: #1d1c1d;
  border-radius: 10px;
}

.new-community-page .border-right {
  border-right: 3px solid #eebe60;
}

.favourite-box {
  height: 40px;
  width: 40px;
  border: 1px solid #ffff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 10px;
}

.score-row {
  font-size: 12px;
}

.score-row img {
  width: 16px;
  display: inline;
  margin-left: 5px;
}

.favourite-box img {
  display: inline;
}

.favourite-box p {
  margin-bottom: 0;
}

.new-community-page .membership-section button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  min-width: 70%;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  padding: 3px 5px;
}

.new-community-page .position-img {
  position: absolute;
  right: -13px;
  top: -60px;
}

.new-community-page .position-img img,
.new-community-page .position-img-1 img {
  height: 110px;
  width: 1px;
}

.board-main-image-box,
.board-inner-image-box,
.leader-board-component {
  position: relative;
}

.leader-board-component .leader-board-info-icon {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 13px;
}

.board-main-image-box .board-image-icon {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.board-inner-image-box .board-image-icon {
  position: absolute;
  left: 5%;
  bottom: -10px;
}

.board-inner-image-box .rounded-image {
  height: 65px;
  width: 65px;
  object-fit: cover;
  border-radius: 5px;
  margin: auto;
  text-transform: uppercase;
}

.search-input input {
  background-color: #222b3e !important;
  margin-bottom: 10px;
  font-size: 13px;
  border-radius: 20px;
  border: none !important;
  height: 35px;
  padding-left: 15px;
}

.search-input textarea {
  background-color: #222b3e !important;
  font-size: 13px;
  border-radius: 5px;
  border: none !important;
  padding-left: 15px;
}

/* .search-input input:focus {
  border: none;
  background-color: #222b3e;
} */

.favourite-buttton-box button {
  background-color: #fff !important;
  border: none !important;
  margin-bottom: 10px;
  font-size: 13px;
  color: #000 !important;
}

.favourite-buttton-box svg {
  color: var(--portal-theme-primary);
  font-size: 20px;
  margin-top: -3px;
}

.new-community-page .position-img-1 {
  position: absolute;
  right: 6px;
  top: -60px;
}

.open-create-post-card .profile-cards {
  min-height: auto;
}

.input-with-profile img {
  border-radius: 50%;
  margin-right: 2%;
}

.input-with-profile .input-field {
  background-color: #222b3e;
  width: 85%;
  border-radius: 30px;
  border: none;
  padding: 0 10px;
  line-height: 37px;
  cursor: pointer;
}

.search-input ::placeholder {
  color: #fff;
}

.likes-post {
  justify-content: start;
  font-size: 13px;
  align-items: center;
}

.likes-post:hover {
  cursor: pointer;
}

.likes-post .MuiAvatarGroup-avatar {
  width: 12px;
  height: 12px;
  font-size: 11px;
}

.likes-post img {
  display: inline;
  height: 15px;
  width: 16px;
}

.likes-post svg {
  font-size: 20px;
}

.likes-post span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.comment-icons {
  padding: 0px;
}

.upload-video .MuiChip-root,
.comment-icons .MuiChip-root {
  width: 100%;
  cursor: pointer;
}

.comment-icons .MuiChip-root {
  border: none;
}

.comment-icons .MuiChip-root:hover {
  background-color: #222b3e;
}

.upload-video .MuiChip-root svg,
.comment-icons .MuiChip-root svg {
  font-size: 20px;
  margin-left: 3px;
  fill: #fff;
}

.upload-video .MuiChip-root span,
.comment-icons .MuiChip-root span {
  color: #fff !important;
  font-size: 12px !important;
}

.post-detail-page .comment-icons .MuiChip-root {
  width: 130px;
  cursor: pointer;
}

.view-comment span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.new-community-page .img-back-border img {
  display: inline;
}

.new-community-page .img-back-border {
  /* background: url(../images/back-batch.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px 15px 4px 11px;
  text-align: center;
  position: relative;
}

/* success-wrapper  */
.new-community-page .success-wrapper {
  padding-top: 80px;
}

.new-community-page .success-wrapper h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.new-community-page .success-wrapper h2 {
  font-size: 15px;
  font-weight: 300;
  margin: 13px 0px;
  color: #fff;
}

.new-community-page .success-wrapper span {
  color: #93b36c;
}

.new-community-page .success-wrapper p {
  line-height: 2;
}

.new-community-page .btn-wrapper button {
  border: none;
  padding: 4px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.new-community-page .btn-wrapper .play-btn {
  background: #fff;
  color: #000;
}

.new-community-page .btn-wrapper .more-info-btn {
  background: #555454;
  color: #fff;
}

.new-community-page .slider-lower-buttons button {
  border: 1px solid #fff;
  border-radius: 7px;
  height: 29px;
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-size: 10px;
  font-weight: 100;
  letter-spacing: 3px;
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.new-community-page .activity-profile img {
  width: 30px;
  margin-top: 5px;
}

.new-community-page .wealth-wallet {
  position: relative;
}

.new-community-page .wealth-wallet .i-icon {
  position: absolute;
  right: 10px;
  top: 5px;
  width: 20px;
  cursor: pointer;
}

.score-right-side h2 {
  margin-bottom: 0.8rem;
  color: #d74336;
}

.search-border {
  border: solid 1px grey !important;
}
.score-right-side h5 {
  margin-bottom: 0.8rem;
}
.menus-child-items {
  padding-left: 30px;
  font-size: 13px;
}
.chip-online {
  background-color: #54d62c;
  font-weight: 500;
}
.chip-offline {
  background-color: var(--portal-theme-primary);
  font-weight: 500;
}
.custome-height {
  height: 145px;
  margin-bottom: 10px;
  overflow: auto;
}
.accordion-summary .MuiAccordionSummary-contentGutters {
  color: #fff !important;
  display: block;
  margin: 20px 0px 0px 0px;
}
.reply-time {
  font-size: 10px !important;
}
.reply-main p {
  margin-bottom: 5px !important;
}

.main-section {
  position: relative;
}

.main-section hr {
  margin: 7px auto;
}

.new-community-page .info-popup-box {
  position: absolute;
  left: -30px;
  top: -100px;
  padding: 5px;
  background-color: #000;
  border-radius: 10px;
  z-index: 2;
  max-width: 350px;
}

.new-community-page .info-popup-box .info-card {
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 10px;
  position: relative;
}

.new-community-page .cross-sigh {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 5px;
}

.new-community-page .cross-sigh span {
  padding: 0px 1px 4px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.new-community-page .cross-sigh span svg {
  fill: #fff;
  color: #fff;
  padding: 2px;
}

.new-community-page .info-popup-box .info-card h2 {
  color: #fff;
  font-size: 20px;
}

.new-community-page .info-popup-box .info-card p {
  /* color: #fff; */
  font-size: 12px;
}

.new-community-page .join-today-btn {
  color: #000;
  background-color: var(--portal-theme-primary);
  font-size: 10px;
  padding: 8px 20px;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 80px;
}

.new-community-page .wealth-wallet span,
.new-community-page .wealth-wallet h5 {
  color: var(--portal-theme-primary) !important;
  font-size: 11px;
}

.new-community-page .wealth-wallet h2 {
  font-size: 12px;
}

.new-community-page .MuiSlider-colorPrimary {
  margin-bottom: 5px;
}

.new-community-page .community-current-pods p {
  font-size: 8px;
}

.new-community-page .community-current-pods svg {
  font-size: 20px;
}

.new-community-page .live-svg svg {
  color: #93b36c;
}

.new-community-page .starting-svg svg {
  color: #ffc107;
}

.new-community-page .total-points .icon-image img {
  width: 30px;
  margin-top: 7px;
}

.new-community-page .upcoming-events p {
  font-size: 10px;
  color: var(--text-secondary-color);
}

.new-community-page .community-star-icon img {
  width: 40px;
  margin: 0 auto;
}

.new-community-page .comment-icons img {
  display: inline;
  width: 16px;
  height: 16px;
  margin: 0;
}

.community-slider,
.increase-scale {
  position: relative;
}

.increase-scale svg {
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 30px;
  color: #000;
  cursor: pointer;
  z-index: 11111 !important;
}

.increase-scale .star-icon svg {
  color: var(--portal-theme-primary);
}

.increase-scale .favourite-loading svg {
  font-size: 125px;
  left: 20px;
}

.community-slider .increase-scale img {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.community-slider .increase-scale img:hover {
  transform: scale(1.3);
}

/* .community-slider .increase-scale {
  z-index: 11;
  transform: scale(5);
  top: 20px;
} */

.hide-on-desktop {
  display: none;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #1d1c1d;
  font-size: 16px;
  border-radius: 50%;
  color: red;
}

.create-post-title-form .preview img {
  width: 100%;
  height: auto;
  max-height: 150px;
}

.post-creator {
  display: flex;
}

.post-creator .creator-name h3 {
  font-size: 13px;
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}

.video-image-selection {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.video-image-selection .selection-box {
  background-color: #222b3e;
  height: 40px;
  width: 40px;
  border-radius: 2px;
  text-align: center;
  margin-right: 10px;
  padding-top: 9px;
  cursor: pointer;
}

.video-image-selection svg {
  color: var(--input-text-color);
  font-size: 20px;
  fill: var(--input-text-color);
}

.video-image-selection .selected svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.video-url-field {
  position: relative;
}

.video-url-field .video-url-cross {
  position: absolute;
  right: 5px;
  top: -5px;
  width: 20px;
  height: 20px;
  background-color: var(--portal-theme-secondary);
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  color: red;
  cursor: pointer;
}

.post-submit-btn {
  /* background-color: #fff; */
  /* color: #000; */
  /* border: none !important; */
  padding: 5px 30px;
}

.show-all-likes-tabs img {
  width: 16px;
}

.show-all-likes .post-creator .creator-name h3 {
  color: #fff;
  text-align: start;
  margin-bottom: 0 !important;
}

.show-all-likes .post-creator .creator-name span {
  font-size: 12px !important;
}

.cross-show-all-likes-model {
  position: absolute;
  right: -10px;
  top: 10px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
}
.sidebar-search-box {
  position: relative;
}
.sidebar-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #d6cfc170;
}
.leaderBoardWidth {
  width: 77%;
}
.sidebar-search-input {
  width: 100%;
  border: solid 2px var(--portal-theme-secondary);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  outline: none !important;
}

.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}

.favourite-videos-list,
.feed-image,
.post-description,
.buttonAssessement {
  cursor: pointer;
}
.completeDate {
  color: var(--portal-theme-primary);
}

.feed-image img {
  width: 100%;
}

.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.post-description a {
  color: var(--portal-theme-primary);
  text-decoration: none;
  z-index: 1;
}

.post-description span {
  z-index: 111;
  color: var(--portal-theme-primary);
}

.favourite-videos-list h3 {
  color: var(--portal-theme-primary);
}

.liking-post img {
  width: 20px !important;
  font-size: 20px;
}

.poster-logo img {
  width: 45px;
}
.new-chat-chip {
  position: absolute;
  bottom: 150px;
  font-size: 10px;
  background-color: var(--portal-theme-primary);
  color: var(--background-primary-color);
  padding: 5px 10px;
  border-radius: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
}

.centerAlign {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -10%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}

.centerAlignProgram {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.centerAlign0 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 30%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}
.profile-comments .affirmation-dots .remove-border {
  top: 5px;
  width: 20px;
  height: 20px;
}

.profile-comments .affirmation-dots .remove-border svg {
  font-size: 16px !important;
}
.new-message-search-input {
  width: 100%;
  border: solid 2px var(--background-secondary-color);
  height: 40px;
  border-radius: 25px;
  background-color: transparent;
  padding-left: 40px;
  color: white;
  border: 2px solid var(--portal-theme-secondary);
}

.new-message-search-input:focus {
  outline: none;
}
.new-message-search-box {
  position: relative;
}
.new-message-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  color: white;
}
.message-user-box {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 5px;
  cursor: pointer;
  color: white !important;
}

.message-user-box p {
  margin-bottom: 0;
}
.new-message-search-input:focus {
  outline: none;
}

.message-user-avatar img {
  border-radius: 50%;
}

.post-detail-page .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px !important;
}

.post-detail-page .main-section {
  max-height: 80vh;
  overflow: auto;
}

.post-detail-box {
  position: relative;
}
em-emoji-picker {
  width: 100%;
}

em-emoji-picker .flex-column .scroll + div {
  width: 100% !important;
}

.write-a-reply textarea {
  color: #fff;
}

.post-detail-box .cross-show-all-likes-model {
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
  background-color: #000000c2;
}

.feed-detail-popup {
  background-color: #000;
  height: 100vh !important;
  overflow-y: scroll;
}

.feed-detail-popup .profile-cards {
  height: 100vh;
  border-radius: 0;
  box-shadow: none;
  overflow: auto;
}

.feed-detail-popup .comment-icons .MuiChip-label {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feed-detail-popup .comment-icons .MuiChip-label img {
  display: inline;
  width: 16px;
}

.leads-search .filter-button {
  height: 40px;
  margin-top: 25px;
}

@media (max-width: 1024px) {
  .new-community-page .membership-section a {
    padding: 7px 5px;
  }

  .new-community-page .membership-section button {
    font-size: 9px;
  }
}

@media (max-width: 912px) {
  .new-community-page .img-back-border-1 {
    /* background: url(../images/back-batch.png); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 9px 5px 9px 0px;
  }
  .leaderBoardWidth {
    width: 85%;
  }

  .new-community-page .img-border {
    height: 88px;
  }

  .new-community-page .join-today-btn {
    margin-top: 100px;
  }

  .input-with-profile .input-field {
    line-height: 47px;
  }

  .favourite-box {
    height: 100px;
    width: 100px;
    font-size: 16px;
  }

  .score-right-side h2 {
    margin-bottom: 1.8rem;
    color: #d74336;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 16px !important;
  }

  .score-right-side h5 {
    font-size: 16px !important;
    margin-bottom: 1.8rem;
  }

  .score-row img {
    width: 20px;
  }

  .hide-on-desktop {
    display: block;
    position: absolute;
    padding: 5px;
    background-color: #000;
    border-radius: 10px;
    z-index: 2;
    max-width: 350px;
    left: auto !important;
    top: 30px !important;
    right: 75px;
  }

  .show-on-desktop {
    display: none;
  }

  .comment-icons .MuiChip-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .new-community-page .wealth-wallet h5 {
    font-size: 16px;
  }

  .community-card-title h4 {
    font-size: 20px;
  }

  .new-community-page .activity-profile img {
    margin-top: 15px;
  }

  .community-card-title h6 {
    font-size: 18px;
  }

  .score img {
    display: inline;
  }

  .new-community-page .wealth-wallet h2 {
    font-size: 20px;
    margin-top: 20px;
  }

  .new-community-page .community-current-pods p {
    font-size: 16px;
  }

  .community-card-title h5 {
    font-size: 20px;
  }

  .new-community-page .upcoming-events p {
    font-size: 17px;
  }

  .total-points p {
    font-size: 30px;
  }

  .new-community-page .total-points .icon-image img {
    width: 60px;
    margin-top: 7px;
    margin-left: 30px;
  }

  .comment-image img {
    height: 70px;
    width: 70px;
    margin-right: 20px;
  }

  .community-card-title .card-title {
    font-size: 20px;
  }

  .reduce-margin-from-para {
    font-size: 16px;
    font-weight: 600;
  }

  /* .poster-name .card-title {
    font-size: 16px;
  } */

  .poster-name .date-color {
    font-size: 14px !important;
  }
  .date-heading {
    color: var(--portal-theme-primary) !important;
  }

  .new-community-page .wealth-wallet .i-icon {
    position: absolute;
    right: 50px;
    top: 25px;
  }

  .new-community-page .wealth-wallet span {
    font-size: 16px;
  }

  .new-community-page .community-current-pods svg {
    font-size: 30px;
  }

  .community-star-icon {
    text-align: center;
  }

  .new-community-page .join-today-btn {
    font-size: 16px;
    padding: 4px 10px;
  }

  .wider-card .mui-card-button,
  .mui-card-button {
    left: 27%;
  }
}

@media (max-width: 540px) {
  .new-community-page .success-wrapper {
    padding-top: 33px;
  }

  .new-community-page .slider-lower-buttons {
    text-align: center;
  }

  .favourite-box {
    width: 80px;
    height: 80px;
    font-size: 14px;
  }

  .score-right-side h2 {
    margin-top: 0 !important;
  }

  .new-community-page .comment-icons {
    padding: 0 5px;
  }

  .likes-post {
    font-size: 11px;
  }

  .post-detail-page .main-section {
    max-height: 100vh;
    overflow: auto;
  }

  .community-main-section {
    overflow: auto;
  }
  .full-calendar .fc-header-toolbar {
    flex-direction: column;
  }
  .full-calendar .fc-header-toolbar .fc-toolbar-chunk {
    margin-bottom: 10px;
  }
  .leads-search .filter-button {
    max-width: 125px;
    margin-left: auto;
    margin-top: 10px;
  }
  .new-community-page .banner-section {
    margin-top: 4rem;
  }
  .header-logo .MuiBox-root {
    width: 175px;
    margin-top: 5px;
  }
}

@media (max-width: 375px) {
  .favourite-box {
    width: 60px;
    height: 60px;
    font-size: 14px;
  }

  .score-right-side h2,
  .score-right-side h5 {
    margin-bottom: 0.8rem;
  }

  .score-row img {
    width: 40px;
  }

  .community-card-title h4 {
    font-size: 13px;
  }

  .community-card-title h6 {
    font-size: 14px;
  }

  .new-community-page .wealth-wallet h5 {
    font-size: 13px;
  }

  .community-card-title h5 {
    font-size: 16px;
  }

  .new-community-page .community-current-pods p {
    font-size: 12px;
  }

  .new-community-page .upcoming-events p {
    font-size: 12px;
  }

  .favourite-buttton-box {
    margin-top: 15px;
  }

  .community-card-title .card-title {
    font-size: 14px;
  }

  .reduce-margin-from-para {
    font-size: 13px;
  }

  .new-community-page .likes-post img {
    display: inline;
    width: 12px;
    margin: 0;
    height: 12px;
  }
}

/* community css */
.hz-scroll {
  /* background-color: #1d1c1d; */
  background: linear-gradient(#1d1c1d 68%, black 32%);
  overflow: auto;
  white-space: nowrap;
  border-radius: 10px;
}

.hz-scroll .hz-scroll-div {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 200px;
}

.hz-scroll .hz-scroll-left {
  position: absolute;
  top: 70px;
  left: -5px;
}

.hz-scroll .hz-scroll-right {
  position: absolute;
  top: 70px;
  right: -5px;
}

.hz-scroll .hz-scroll-icon {
  color: #000;
  background-color: #b88d38;
  border-radius: 50%;
  font-weight: 800;
  padding: 2px;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;
}

.hz-scroll .hz-scroll-icon:hover {
  background-color: var(--portal-theme-primary);
}

.hz-scroll .gb-left {
  border-left: solid 2px #e4b047;
}

.hz-scroll::-webkit-scrollbar {
  display: none;
}

.img-border {
  margin-top: -92px;
}

.img-border img {
  height: 110px;
}

.hz-scroll-title-odd {
  position: relative;
  top: -5px;
  z-index: 30;
}

.hz-scroll-title-even {
  position: relative;
  top: -5px;
  z-index: 30;
  right: 100px;
}

.like-comment span {
  color: #969696;
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.post-description span {
  z-index: 111;
}

.community-slider,
.increase-scale {
  position: relative;
}

.slick-list,
.slick-slider {
  display: block;
  position: relative;
}

.event-slider .slick-next:before,
.event-slider .slick-prev:before {
  color: var(--portal-theme-primary);
  color: var(--portal-theme-primary);
}

.slick-slide > div {
  margin: 0 10px;
}

.like-comment span {
  color: var(--text-secondary-color);
  margin-right: 10px;
  margin-top: 5px;
}

.like-comment span:hover {
  text-decoration: underline;
}

.liked-comment {
  color: var(--portal-theme-primary) !important;
}

.like-reply-box span {
  color: #dcdada;
}

.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 8px;
  margin-left: -5px;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 3px;
}

.create-post-title-box {
  position: relative;
}

.create-post-title-box span {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 30px;
  width: 30px;
  font-size: 17px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  color: red;
}

.create-post-title-box h2 {
  color: #fff;
}

.create-post-title-form .upload-button label {
  width: 100%;
  height: auto;
  background-color: #222b3e;
  padding: 0 !important;
}

.create-post-title-form .upload-button {
  position: relative;
}

.create-post-title-form .upload-button span {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  background-color: #1d1c1d;
  font-size: 16px;
  border-radius: 50%;
}

.create-post-title-form .preview img {
  width: 100%;
  height: auto;
  max-height: 150px;
}

.post-creator {
  display: flex;
}

.post-creator .creator-name h3 {
  font-size: 13px;
}

.post-creator img {
  height: 34px;
  width: 34px;
  border-radius: 50%;
}

.post-creator select {
  padding: 1px 5px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}

.post-creator select option {
  background-color: var(--sidebars-background-color);
}

.video-image-selection svg {
  color: var(--input-text-color);
  font-size: 20px;
  fill: var(--input-text-color);
}

.video-image-selection .selected svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.video-url-field {
  position: relative;
}

.post-submit-btn {
  /* background-color: #fff; */
  /* color: #000; */
  /* border: none !important; */
  padding: 5px 30px;
}

.show-all-likes-tabs img {
  width: 16px;
}

.show-all-likes .post-creator .creator-name h3 {
  color: #fff;
  text-align: start;
  margin-bottom: 0 !important;
}

.show-all-likes .post-creator .creator-name span {
  font-size: 12px !important;
}

.like-profile-image {
  position: relative;
}

.like-profile-image .tiny-like-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  bottom: -5px;
}

.like-profile-image .tiny-like-icon img {
  width: 13px;
  height: auto;
}

.favourite-videos-list,
.feed-image,
.post-description,
.pointer {
  cursor: pointer;
}

.pointer_cursor span {
  cursor: pointer;
}

.feed-image img,
.feed-image iframe {
  width: 100%;
}

.post-description {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  margin-bottom: 10px;
}

.profile-comments .post-description p {
  font-weight: 300;
  display: inline;
  font-size: 14px;
}

.post-description a {
  color: var(--portal-theme-primary);
  text-decoration: none;
  z-index: 1;
}

.post-description span {
  z-index: 111;
  color: var(--portal-theme-primary);
}

.favourite-videos-list h3 {
  color: var(--portal-theme-primary);
}

.liking-post img {
  width: 20px !important;
  font-size: 20px;
}

.poster-logo img {
  width: 45px;
}

.modal-theme {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
}

.create-post-title-form .modal-theme {
  padding: 10px 10px !important;
}

.coin-icon {
  width: 22px;
}

.profile-gallery-photo {
  padding: 0 5px !important;
  text-align: center;
}

.profile-gallery-photo span {
  margin-top: 5px;
  display: block;
  font-size: 16px;
}

.profile-gallery-photo img {
  border-radius: 5px;
}

.avatar-group .MuiAvatar-circular {
  width: 30px;
  height: 30px;
  font-size: 12px;
}

.comment-icon .svg-color svg {
  width: 20px;
  height: 20px;
  margin-top: -1px;
}

.comment-image img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
}

.avatar-adjust {
  margin-top: 5px;
  margin-right: 5px;
}
.avatar-adjust-new {
  margin-right: 5px;
}

.like-reply-box span {
  color: #dcdada;
}

/* custom scrollbar start style */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

/* custom scrollbar end style*/

.comment-icons img {
  height: 16px;
  width: 16px;
}

.form-control,
.form-control:focus {
  /* color: #fff; */
  color: var(--text-primary-color);
}

.single-comment-card {
  background-color: #222b3e;
  padding: 10px;
  border-radius: 3px;
}

.single-comment-card .post-description {
  margin-bottom: 0;
}

.notification-popover-paper {
  top: 5px !important;
}

.notification-popover {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.notification-title {
  color: var(--portal-theme-primary);
}
.count-color {
  cursor: pointer !important;
  text-transform: capitalize;
  /* width: 66px !important; */
  height: 24px !important;
  border-radius: 50% !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  text-align: center !important;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 17px 16px;
  /* color: rgb(99, 115, 129); */
  color: black;
  font-size: 0.742857rem;
  font-family: Montserrat, sans-serif;
  background-color: var(--portal-theme-primary);
  font-weight: 700;
}

.paid {
  text-transform: capitalize;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: rgb(34, 154, 22);
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(84, 214, 44, 0.16);
  font-weight: 700;
}

.normal {
  text-transform: capitalize;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  height: 25px;
  width: 25px;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  /* color: rgb(99, 115, 129); */
  color: white;
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(145, 158, 171, 0.16);
  font-weight: 700;
}
.free {
  text-transform: capitalize;
  height: 25px;
  width: 66px !important;
  height: 24px !important;
  border-radius: 16px !important;
  text-align: center;
  line-height: 0;
  border-radius: 8px;
  cursor: default;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 8px;
  color: white;
  font-size: 0.642857rem;
  font-family: Montserrat, sans-serif;
  background-color: rgba(24, 144, 255, 0.16);
  font-weight: 700;
}
.notification-item,
.notification-item:hover {
  background-color: rgba(145, 158, 171, 0.08) !important;
  color: gray !important;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

.centered-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.notification-item-unread span {
  color: #fff !important;
}

.notification-item-unread,
.notification-item-unread:hover {
  background-color: rgba(145, 158, 171, 0.16) !important;
  /* color: white !important; */
}
.notifications-delete-button {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 18px;
  visibility: hidden;
}
.notification-item:hover .notifications-delete-button,
.notification-item-unread:hover .notifications-delete-button {
  visibility: visible;
}
.load-more-notification-btn button {
  margin: 10px auto;
  padding: 4px 8px;
  border: 1px solid #fff;
}

.tiny-like-icon-notification img {
  width: 16px !important;
}

.notification-icons-btn {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
}

.css-35q5go {
  background-color: var(--sidebars-background-color);
  color: var(--input-text-color);
}

.community-card-title h4 {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3px;
  margin-bottom: 0;
}

.community-card-title h6 {
  color: var(--text-secondary-color);
  font-size: 14px;
  margin-top: 10px;
}

.video-portal-border {
  border-radius: 5px;
  border-width: 2px;
  padding: 2px;
  background-image: linear-gradient(0deg, transparent, rgba(181, 157, 78, 0.5));
}

/* .dashboard-background {
  background-image: url("../images/bg-dashboard.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 2px;
  padding-bottom: 20px;
} */
.css-14ewge7 {
  color: white !important;
}

.fixed-top {
  /* position: fixed; */
}

.recordings-card {
  margin-bottom: 20px;
  padding: 20px;
}

.program-category-btn {
  text-align: right;
  margin-bottom: 20px;
}

.chat-message-body .preview img {
  cursor: pointer;
}

.accordion-detail-earning p {
  color: #fff;
}

.download-pdf-button {
  background-color: var(--button-background-color);
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  font-style: normal;
  padding: 8px 20px;
  text-decoration: none;
}

.range-slider span {
  color: var(--portal-theme-primary);
}

.MuiPickersDay-today {
  background-color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary) !important;
}

.goal_achieved_white {
  color: #fff !important;
  font-size: 20px;
}

.goal_achieved_white #date_text_string {
  color: var(--portal-theme-primary);
  font-size: 26px;
  margin-top: 4px;
}

.MuiCalendarOrClockPicker-root {
  color: #fff !important;
}

/* tiny logo */
.tox-statusbar__branding {
  display: none !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.MuiTablePagination-toolbar {
  align-items: baseline !important;
}
.pagination-style button {
  background-color: var(--portal-theme-secondary);
  color: #fff;
}

.pagination-space-from-bottom p {
  margin-bottom: 0px !important;
  color: #fff;
}
/* assessment history */
.assetssment-tab-para h4 {
  font-size: 20px;
  margin-bottom: 16px;
  color: #ffffff;
}

.assetssment-tab-para p {
  font-size: 14px !important;
  /* color: #ccc9c4; */
}

.assesment-list li {
  background: var(--sidebars-background-color);
  border: 1px solid rgba(71, 66, 34, 0.3);
  padding: 0px 14px;
  padding-bottom: 14px;
  border-radius: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
  color: #ccc9c4;
  margin-top: 16px;
}

.assesment-list li > span {
  padding-right: 20px;
  margin-top: 14px;
}

.assesment-list li .FaStar-button {
  margin-top: 14px;
}

.star {
  display: inline;
  cursor: pointer;
}

.star > button {
  color: #b59d4e;
  background: transparent;
  border: 1.5px solid #b59d4e;
  border-radius: 50%;
  margin: auto 3px;
  /* padding: 3px 5px 15px; */
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
}

.star > button p {
  font-size: 13px;
  font-weight: 600;
}
.question-background {
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
}
.def-sale-section-count {
  background: #00ab55;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  /* min-width: 1rem; */
  max-height: 26px;
  padding: 4px;
  margin-top: 5px;
}
.manu-place {
  padding-top: 13px;
  padding-right: 10px;
}
.card {
  background-color: var(--sidebars-background-color) !important;
}
.span-color {
  color: white;
  cursor: pointer;
}
.sale-email-link {
  color: var(--portal-theme-primary);
}

.span-color {
  color: white;
  cursor: pointer;
}
.span-decoration {
  text-decoration: underline;
  margin-right: 5px;
  font-size: 12px;
}
.email-color {
  color: var(--portal-theme-primary) !important;
}
.button-style-remove {
  background-color: transparent !important;
  box-shadow: none;
  text-transform: capitalize;
}

.top-pedding {
  padding-top: 20px;
}
/* .css-hz1bth-MuiDialog-container {
  height: 110%;
} */
.move-fixes-popup-height {
  height: 85%;
}
.paper-color-leads:hover {
  background-color: var(--portal-theme-primary);
  color: var(--input-text-color);
}

.selectedAssociateColor {
  background-color: var(--portal-theme-primary);
  color: var(--input-text-color);
}
.assessment-btn button {
  background: #b59d4e;
  color: #000000;
  padding: 8px 40px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  border: none !important;
  outline: none !important;
}
.support_notification_sidebar_page {
  height: 15px;
  margin-left: 10px;
  color: #8d1616 !important;
  fill: #8d1616 !important;
}
.Nurture_online {
  height: 15px;
  margin-left: 10px;
  color: green !important;
  fill: green !important;
}
.Nurture_offline {
  height: 15px;
  margin-left: 10px;
}
.support_notification_sidebar_page_alert {
  height: 15px;
  margin-left: 10px;
  color: transparent !important;
  fill: transparent !important;
}
.booking-detail .booking-question h6 {
  color: var(--portal-theme-primary);
}
.postioning {
  position: absolute;
  top: -1px;
  left: 14px;
}
.booking-normal-detail p,
.booking-normal-detail b {
  font-size: 16px !important;
}
.booking-questions p {
  color: var(--input-text-color);
}

.cursor-pointer {
  cursor: pointer;
}

.set-optoin {
  justify-content: space-between;
}
.height {
  height: 93vh;
  display: flex;
  flex-direction: column;
}
/* member profile new styling  */
.dashboard-card {
  box-shadow: none;
  /* text-align: center; */
  color: #fff;
  background-color: var(--sidebars-background-color);
  border-radius: 16px;
  padding-top: 24px;
  /* padding: 24px; */
}

.dashboard-card .title {
  color: var(--portal-theme-primary);
  font-size: 16px;
  margin-left: 24px;
  margin-bottom: 0 !important;
}
.dashboard-card .text-section {
  margin-top: 24px;
}
.dashboard-card .main-box {
  padding-right: 24px;
  padding-left: 24px;
}

.dashboard-card .icon-wrapper-style {
  margin: auto;
  display: flex;
  border-radius: 50%;
  align-items: center;
  padding: 24px;
  margin: auto auto 24px;
  width: 64px;
  height: 64px;
  justify-content: center;
  background-image: linear-gradient(
    135deg,
    rgba(34, 154, 22, 0) 0%,
    var(--portal-theme-secondary) 100%
  );
}

.dashboard-card h3 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.dashboard-card .date-color {
  font-weight: normal !important;
  font-size: 15px !important;
}
.source-session h3 {
  font-weight: normal;
  font-size: 15px;
}

.source-session p {
  font-size: 14px;
  color: var(--text-secondary-color);
}
.dashboard-progress-box {
  position: relative;
}
.dashboard-progress-icon {
  height: 80px !important;
  width: 80px !important;
}
.dashboard-progress-text {
  top: 24px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  text-align: center;
}

.dashboard-progress-text-title {
  font-size: 20px;
}
.sideCard {
  height: 316px !important;
  overflow-y: scroll;
}
/* member profile new styling end */

.button-link button {
  text-decoration: underline;
}
.remove-border {
  padding-top: 0px;
  border: none !important;
  margin: 0 !important;
  z-index: 10 !important;
}
.feed-level .MuiChip-root {
  color: var(--portal-theme-primary) !important;
  height: 16px;
  font-size: 10px;
  margin-top: 2px;
}
.feed-level svg {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.small-delete-button {
  background-color: #ff4842 !important;
  border: 1px solid #ff4842;
  border-radius: 5px;
  color: #fff;
  padding: 8px 16px;
  outline: none;
}
.MuiClockPicker-root span {
  color: #fff;
}
.MuiAutocomplete-tag {
  color: #fff;
}

.diary-icon-remove {
  color: red;
  fill: red;
  margin-bottom: 0px;
  cursor: pointer;
}

.links-icons svg {
  font-size: 16px;
}
.event-details {
  position: relative;
}
.event-title-dot {
  height: 11px;
  width: 11px;
  margin-top: 3px;
  margin-right: 3px;
  border-radius: 50%;
}

.calender-title {
  color: #fff;
}
.fc-daygrid-day-bottom {
  z-index: 0 !important;
}

.fc-daygrid-day-events {
  padding-top: 1rem;
  color: white;
  /* display: flex; */
  position: absolute !important;
  top: 10px;
}

.calender-events-text {
  color: white;
}
.calendar-week-card {
  background-color: var(--sidebars-background-color);
  border: 7px solid #121826;
  border-radius: 13px;
}
.calendar-card {
  background-color: var(--sidebars-background-color);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.calendar-week-card {
  background-color: var(--sidebars-background-color);
  /* border: 7px solid #000; */
  border-radius: 13px;
}

.calendar-week-card h4 {
  font-size: 14px;
  margin-bottom: 2px;
}

.calendar-card .card-event-day {
  padding: 5px;
  color: var(--portal-theme-primary);
  text-align: center;
}

.calendar-card .calendar-dot {
  height: 13px;
  width: 13px;
  margin: 0 6px;
  border-radius: 50%;
  border: 1px solid #fff;
}

.calendar-card .card-event-title {
  padding: 5px;
}

.calendar-card .card-event-title:hover {
  color: var(--portal-theme-primary);
}

.calendar-card .card-event-title span {
  color: var(--text-secondary-color);
  font-size: 13px;
}

.calendar-card .week-event-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.position-relative {
  position: relative;
}
/* change time image  */
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
.MuiPopover-paper {
  background-color: #1d1c1d !important;
  background-color: var(--sidebars-background-color) !important;
  color: #fff !important;
  color: var(--input-text-color) !important;
}
.heading_programs {
  padding: 8px;
  border-bottom: 1px solid rgb(66, 66, 66);
  font-weight: 500;
  color: var(--portal-theme-primary);
  padding-right: 30px;
}
.icon-container {
  position: absolute;
  top: 7px;
  right: 5px;
  cursor: pointer;
}
.icon-container .MuiSvgIcon-fontSizeSmall {
  color: var(--portal-theme-primary);
}
.view-more {
  color: var(--portal-theme-primary);
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.2s;
  text-transform: capitalize;
}
.view-more:hover {
  color: var(--portal-theme-primary);
  cursor: pointer;
  opacity: 8;
}
input[type="time"] {
  z-index: 0;
  position: relative;
}

input[type="time"]:after {
  content: "";
  background-image: url("../images/clock.png");
  height: 20px;
  width: 20px;
  background-size: contain;
  z-index: -1;
  position: absolute;
  top: 18px;
  right: 2%;
}

.booking-timezone {
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}
.general-popup-model {
  max-width: 500px;
  position: relative;
  background-color: var(--sidebars-background-color) !important;
  color: #fff;
}
.general-popup-model-width {
  max-width: 500px;
  width: 100% !important;
  position: relative;
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.general-popup-model hr {
  margin: 0.5rem 0;
  border: 1px solid #fff;
}

.general-popup-model .popup-title {
  padding: 0px 40px;
  color: #ffff;
  text-align: center;
}

.general-popup-model .popup-title h2 {
  font-size: 25px;
}

.general-popup-model .cross-icon {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--portal-theme-primary);
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.booking-status-chip {
  width: 115px !important;
  padding: 10px 0;
}
.notification-popover-loader {
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.detail_description-iframe iframe {
  max-width: 100% !important;
}

.programmes-detail-description img {
  height: auto;
}
.profile-comments.inception {
  background-color: var(--sidebars-background-color);
  color: #fff;
}

.like-reply-inception-box {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
}

.like-reply-inception-box .action-box {
  padding: 2px 5px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.like-reply-inception-box img {
  display: inline-block;
  width: 14px;
}

.like-reply-inception-box svg {
  font-size: 14px;
  color: #fff;
  fill: #fff !important;
}

.comment-like-heart .comment-like-heart-count {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 8px;
  margin-left: -5px;
}

.comment-like-heart .comment-like-heart-image {
  min-width: 20px;
  text-align: center;
  border: 1px solid #333232;
  border-radius: 50%;
  padding: 0px 3px;
}

.live-event-comment-message .comment-like-heart-image,
.live-event-comment-message .comment-like-heart-count {
  max-height: 20px;
}

.live-event-comment-message img {
  width: 12px;
  height: 11px;
  margin-top: 4px;
}

.live-event-comment-message {
  margin-top: 5px;
}

.event-comment-like-box .comment-like-heart {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.position-relative {
  position: relative !important;
}
.live-comments {
  position: relative;
  background-color: transparent;
  border: 1px solid #101010;
}
.likes-model-tabs img {
  margin-right: 8px;
  width: 16px;
  height: 14px;
  margin-top: 2px;
}
.cross-show-all-likes-model {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
  text-align: center;
  cursor: pointer;
}

.dashborad-count-card {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  border-radius: 16px;
}
.count-card-icon {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  align-self: center;
}

.dashborad-count-card h4 {
  font-weight: 400;
  font-size: 2.125rem;
  margin-top: 24px;
  margin-bottom: 0;
}

.dashborad-count-card h6 {
  color: var(--input-text-color) !important;
  font-size: 0.875rem;
  opacity: 0.72;
}

.user-note-count {
  padding: 1px 10px 0 6px;
  background-color: var(--portal-theme-primary);
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 12px;
  margin-right: 5px;
}
.count-color-user {
  cursor: pointer !important;
  text-transform: capitalize;
  /* width: 66px !important; */
  height: 19px !important;
  border-radius: 50% !important;
  height: 25px;
  margin-right: 5px;
  width: 18px;
  line-height: 0;
  border-radius: 8px;
  text-align: center !important;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 3px 0px;
  /* color: rgb(99, 115, 129); */
  color: white;
  font-size: 9px;
  font-family: Montserrat, sans-serif;
  background-color: var(--portal-theme-primary);
}
.answer-heading {
  color: white;
  margin-bottom: 8px;
}
.position {
  position: absolute;
  z-index: 99;
  left: 21px;
  bottom: 0px;
  height: 12px;
  color: var(--portal-theme-primary) !important;
  fill: var(--portal-theme-primary) !important;
}
.position-online {
  position: absolute;
  z-index: 99;
  left: 21px;
  bottom: 0px;
  height: 12px;
  color: green !important;
  fill: green !important;
}
.payment-request-heading {
  font-weight: 600;
}
.text-visible {
  color: #e0e0e0 !important;
}
.payment-heading {
  font-weight: 400;
  font-size: 16px;
}
.questions-heading {
  color: var(--portal-theme-primary);
}

.alignment {
  display: grid;
}
.MuiInputBase-inputSizeSmall {
  color: white;
}
.note-date {
  font-size: 10px;
  color: #939292;
  margin-bottom: -11px;
}
.wheel-of-life-icon {
  height: 25px;
  width: 25px;
}

.answer-history,
.drawer-user-name,
.responce-messages p,
.responce-messages input,
.reply_text_color {
  color: #fff !important;
}

.goal_statement_yes_icons img {
  height: 25px;
  margin-top: 11px;
}

.css-1pxwcq7-MuiTooltip-tooltip {
  border: 1px solid gray !important;
}

.for-list .MuiAutocomplete-clearIndicator {
  display: none !important;
}

.for-list .MuiInputLabel-formControl {
  color: rgb(163, 163, 163);
  font-size: 15px;
}
.for-hide .MuiInputLabel-formControl {
  color: rgb(163, 163, 163);
  font-size: 15px;
  display: none;
}
.setting-card {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  padding: 20px;
}
.cross {
  cursor: pointer;
  font-size: 30px;
  margin-left: 40px;
  margin-top: 11px;
}
.question-survey-cross {
  margin-top: 11px;
  margin-left: 6px;
  font-size: 30px;
  cursor: pointer;
}

.event-in-post {
  background-color: var(--background-primary-color);
  padding: 6px 6px 10px;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  clear: both;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem;
}

.post-event-button {
  padding: 8px 16px;
  border-radius: 5px;
  text-align: center;
}

.post-event-button:hover {
  color: inherit !important;
}

.history-icon {
  position: absolute;
  right: 0px;
  top: -25px;
}
.history-icon .css-i4bv87-MuiSvgIcon-root {
  width: 20px !important;
  cursor: pointer;
}
.status-buttons {
  padding: 11px 90px;
  border-radius: 8px;
}
.history-down-icon {
  font-size: 35px;
  text-align: center;
  color: white;
}

.date_and_income_value {
  margin-top: -20px;
}
.community-main-section .profile-cards {
  min-height: auto;
}
.profile-cards.main-section {
  overflow: auto;
}

#show-dialog-on-full .MuiDialog-paperScrollPaper {
  width: 100vw !important;
  height: 100vh !important;
  min-width: 100vw !important;
  min-height: 100vh !important;
  margin: 0 !important;
  position: relative;
}
.MuiFormControl-root {
  width: 100% !important;
}
.heading-calender {
  color: white;
}

.heading-calender svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.back-screen-button {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  margin-bottom: 10px;
}
.event-submit-button {
  background-color: var(--portal-theme-primary) !important;
  color: #fff !important;
}
.event-details h1 {
  font-size: 30px;
}
.icon-event-detail .close-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}
.icon-event-detail {
  font-size: 18px;
  right: 1%;
  top: 10px;
  justify-content: end;
}
.event-details .date-color {
  color: var(--text-secondary-color) !important;
  font-size: 14px !important;
}
.general-popup-model {
  position: relative;
  max-width: 560px;
  min-width: 520px;
}
.general-popup-model-max-width {
  position: relative;
  max-width: 1000px;
  min-width: 1000px;
  z-index: 1200 !important;
}
.hide-index {
  z-index: 1200 !important;
}
.icon-event-detail .other-detail-icon {
  height: 35px;
  width: 35px;
  text-align: center;
  padding-top: 3px;
  border-radius: 50px;
  color: var(--portal-theme-primary);
  cursor: pointer;
  margin-right: 3px;
}
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}
.icon-event-detail .other-detail-icon:hover {
  background-color: var(--portal-theme-secondary);
}
.icon-event-detail .close-detail-icon svg,
.icon-event-detail .other-detail-icon svg {
  font-size: 16px !important;
}

.chat-send-input-editor .tox-menubar,
.chat-send-input-editor .tox .tox-toolbar__primary,
.chat-send-input-editor .tox .tox-toolbar,
.chat-send-input-editor iframe {
  background-color: var(--sidebars-background-color) !important;
}

.chat-send-input-editor button,
.chat-send-input-editor iframe,
.chat-send-input-editor svg {
  color: var(--text-primary-color) !important;
  fill: var(--text-primary-color) !important;
}
.chat-send-input-editor .tox-edit-area__iframe {
  color: #fff !important;
}

.chat-send-input-editor button {
  background-color: inherit !important;
}

.chat-send-input-editor button.tox-tbtn--enabled {
  background-color: var(--portal-theme-primary) !important;
}

.chat-send-input-editor .tox-tinymce {
  border-radius: 5px;
}
/* 
.chat-send-input-editor .tox-toolbar__group {
  margin-top: -39px !important;
  margin-left: 108px !important;
} */

.tox-collection__item-label h1,
.tox-collection__item-label h2,
.tox-collection__item-label h3,
.tox-collection__item-label h4,
.tox-collection__item-label h5,
.tox-collection__item-label h6,
.tox-collection__item-label pre,
.tox-collection__item-label p {
  color: #222f3e !important;
}

.chat-list-last-message h1,
.chat-list-last-message h3,
.chat-list-last-message h4,
.chat-list-last-message h5,
.chat-list-last-message h6,
.chat-list-last-message h2 {
  font-size: 14px !important;
}

.chat-send-input-editor .tox-statusbar {
  display: none !important;
}

iframe {
  max-width: 100% !important;
  width: 100% !important;
}
/* .css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 0;
} */
.menu-icon-settings {
  margin-left: 20px !important;
  background-color: transparent !important;
}
.notifications-circle {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  font-size: 10px;
  margin-right: 5px;
}
.notifications-circle-seen {
  color: rgb(51 56 61 / 8%);
  fill: rgb(51 56 61 / 8%);
}
.notification-item-box {
  display: flex;
  margin-bottom: 1px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 0;
}
@media screen and (max-width: 989px) {
  .general-popup-model {
    position: relative;
    max-width: 100%;
    min-width: 95%;
  }
}
@media screen and (max-width: 820px) {
  .general-popup-model {
    position: relative;
    max-width: 100%;
    min-width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .calendar-event-title {
    display: none;
  }
  .general-popup-model {
    position: relative;
    max-width: 100%;
    min-width: 100%;
  }
}
.chat-last-seen {
  font-size: 10px;
  font-weight: 400;
  color: var(--text-secondary-color);
  width: 100%;
  text-transform: none;
}
.css-1m6yy9o {
  color: white !important;
}
.updated-date {
  position: absolute;
  right: 5%;
}

.voice-recorder {
  text-align: center;
  padding: 20px;
}

.start-button,
.stop-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.stop-button {
  background-color: #f44336;
}

.audio-player {
  margin-top: 20px;
}

.sound-wave {
  border: 2px solid #4caf50; /* Change border properties */
  background-color: #f0f0f0; /* Change background color */
  border-radius: 8px; /* Add rounded corners */
  width: 100%; /* Make the canvas span the full width of its container */
  height: 100px; /* Set a fixed height for the canvas */
  display: none;
}

.recording-slider {
  width: 100%;
  background-color: var(--sidebars-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  margin-top: 2px;
}

.recording-slider .inner-slider {
  width: 84%;
  height: 7px;
  background-color: var(--portal-theme-secondary);
}

.recorded-audio {
  width: 10%;
  height: 100%;
  background-color: var(--portal-theme-primary);
}

.recorded-time {
  padding-left: 10px;
  color: var(--text-secondary-color);
  width: 53px;
}
.action-buttons {
  /* background-color: var(--portal-theme-secondary); */
  display: flex;
  /* height: 40px;
  margin-top: 2px;
  padding: 0 10px;
  margin-right: 5px;
  align-items: center; */
  margin-right: 5px;
}

.action-buttons svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  cursor: pointer;
}

.custom-audio-player svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  cursor: pointer;
}

.custom-audio-player {
  display: flex;
  align-items: center;
}

.custom-audio-player .played-audio {
  height: 100%;
  max-width: 100% !important;
  background-color: var(--portal-theme-primary);
  border-radius: 3px;
}

.custom-audio-player .full-player {
  width: 100px;
  height: 3px;
  background-color: #000;
  margin-left: 5px;
  border-radius: 3px;
}

.audio-loader {
  height: 16px !important;
  width: 16px !important;
  margin-top: 5px;
}

.toltip_border {
  position: relative;
}

.notes-popup {
  width: 400px;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  top: 5;
  text-wrap: wrap;
  background-color: var(--background-primary-color);
  padding: 10px;
  z-index: 1;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 10px;
}
.tox-notifications-container {
  visibility: hidden !important;
}
.attach {
  display: flex;
  align-items: baseline;
}

.program_lock_img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-right: 12px;
}
.textarea-autosize {
  outline: none;
}

.textarea-autosize:focus-visible {
  border: 2px solid var(--portal-theme-primary) !important;
}

.textarea-autosize-box .text-color:focus {
  outline: 1px solid var(--portal-theme-primary) !important;
}

.field-container {
  width: 92%;
}
/* .cross-icon {
  border-radius: 50px;
  color: #fff;
  font-size: 13px;
  height: 25px;
  padding-top: 2px;
  position: absolute;
  right: 1%;
  text-align: center;
  top: 5px;
  width: 26px;
} */
.static-date-picker {
  width: 100%;
}
.css-j7qwjs {
  display: flex;
  flex-direction: column;
}
.MuiDatePickerToolbar-root {
  display: none;
}
.MuiCalendarOrClockPicker-root {
  background-color: var(--background-primary-color);
}

.full-open-calender .MuiCalendarPicker-root {
  background-color: transparent !important;
}
.static-date-picker {
  width: 100%;
  background-color: transparent;
  /* padding-left: 3rem; */
}

.static-date-picker .MuiPickerStaticWrapper-content {
  background-color: transparent;
}

.static-date-picker .css-epd502,
.static-date-picker .MuiCalendarPicker-root,
.static-date-picker .MuiDayPicker-header {
  width: 100%;
}

.static-date-picker .MuiDayPicker-header,
.static-date-picker .MuiDayPicker-weekContainer {
  justify-content: space-between;
}

.static-date-picker .MuiPickersCalendarHeader-root {
  padding-left: 16px !important;
  padding-right: 7px;
}

/* .static-date-picker .MuiDayPicker-header {
  padding-left: 15px;
} */

.static-date-picker .MuiPickersDay-root,
.static-date-picker .MuiDayPicker-weekDayLabel {
  font-size: 1rem !important;
}

.visioneer-btn svg {
  height: 20px;
}

@media (max-width: 912px) {
  .static-date-picker {
    padding-left: 0;
    margin-top: 25px;
  }
}
.calender .MuiSvgIcon-root {
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.5s;
}
.calender .MuiSvgIcon-root {
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.5s;
}
.calender .MuiPickersCalendarHeader-label {
  font-weight: 500;
}
.mini-image {
  height: 90px;
  width: 125px;
  padding-top: 10px;
  cursor: pointer;
}
.text-upload {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: var(--portal-theme-primary);
}
.art {
  position: absolute;
  top: 0px;
  opacity: 0.3;
  display: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.main:hover + .art {
  display: block !important;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.main1:hover + .art1 {
  display: block !important;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
.main2:hover + .art2 {
  display: block !important;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.art1 {
  position: absolute;
  top: 0px;
  opacity: 0.5;
  display: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.art2 {
  position: absolute;
  top: 0px;
  opacity: 0.5;
  display: none;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.css-1ri3awb {
  background: transparent !important;
}
.top-section-video iframe {
  border-radius: 15px;
}
.top-section-video .MuiDialogActions-spacing {
  display: none;
}
.new-icon {
  display: flex;
  margin-left: 10px;
  margin-top: 22px;
}
.date-transaparent .inputs-fields {
  background-color: transparent;
}
.width {
  width: 25%;
}
.cursorStyle {
  cursor: pointer;
}
.summary {
  cursor: default !important;
}
.preview .track-image-cross {
  position: absolute;
  right: 6px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  top: -6px;
}
.upload-button-cross label {
  text-align: center !important;
  background-color: #c8c8c710;
  padding: 15px;
  color: gray;
  border-radius: 5px;
  cursor: pointer;
  height: 76px;
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 540px) {
  .width {
    width: 100%;
  }
}
.afrim {
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}

.persons-list-billing {
  border: 1px solid var(--popup-background-color);
  border-radius: 10px;
}
.secondary-heading {
  font-size: 20px;
  /* font-weight: 600;  */
  color: white !important;
}
.position-completed {
  z-index: 99;
  bottom: 0px;
  height: 12px;
  color: var(--portal-theme-primary) !important;
  fill: var(--portal-theme-primary) !important;
}
.position-notcompleted {
  z-index: 99;
  bottom: 0px;
  height: 12px;
  color: var(--portal-theme-primary) 50 !important;
  fill: var(--portal-theme-primary) 50 !important;
}
.slider-container .css-1l0glfa-MuiSlider-markLabel,
.css-13o9n0h-MuiSlider-markLabel,
.css-13ikhxx {
  color: var(--portal-theme-primary);
}
.leaderBoardWidth-performance {
  width: 100%;
}
.leader-board-postion {
  text-align: center;
  text-transform: capitalize;
  font-size: 17px;
  color: var(--portal-theme-primary);
  font-weight: 600;
}

.select-multiple-level {
  font-size: 11px;
  border-radius: 5px !important;
}

.select-multiple-level .MuiSelect-multiple {
  padding: 5px 10px;
}

.select-multiple-level-controls .MuiInputLabel-formControl {
  transform: translate(16px, -3px) scale(0.75) !important;
}

.accountability-slider .MuiSlider-valueLabelOpen {
  background-color: #040a10 !important;
}

.accountability-slider {
  background-color: var(--sidebars-background-color);
  padding: 20px 30px 20px 20px;
  border-radius: 5px;
}

.input-accountability-box {
  /* border: 2px solid var(--sidebars-background-color); */
  padding: 13px 20px 10px;
  margin-bottom: 0px;
  border-radius: 5px;
  position: relative;
}

.input-accountability-label {
  /* position: absolute;
  top: -11px; */
  font-size: 16px;
  color: #faf8f8;
}

.input-slider {
  display: flex;
}

.input-slider .colored-last-value {
  color: var(--portal-theme-primary);
}

.input-slider .MuiSlider-valueLabelOpen {
  background-color: #040a10 !important;
}

@media (max-width: 540px) {
  .accountability-slider {
    flex-direction: column;
    padding: 10px 20px;
  }
  .slider-container {
    width: 100% !important;
  }
}
.dynamite-diary .mini-cards {
  position: relative;
  width: 100%;
  align-items: center;
  border-radius: 10px;
  background: var(--sidebars-background-color);
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 15p;
  color: white;
  font-size: 15px;
  border: 2px solid rgba(71, 66, 34, 0.3);
  margin-top: 0px;
}

.mini-cards h4 {
  font-weight: 400 !important;
}
.dynamite-diary p {
  color: #ccc9c4;
  font-size: 12px;
}
.diary-first-heading {
  color: #ccc9c4;
}
.icon-color {
  color: var(--portal-theme-primary) !important;
  fill: var(--portal-theme-primary) !important;
}
.color-white {
  color: white;
}

.px-10 {
  padding-left: 100px;
  padding-right: 100px;
}

.full-page-popup {
  background-color: var(--sidebars-background-color);
}

.setIconPosition {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: -18px;
}

.whatsapp-send-input {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  padding: 9px 30px 9px 15px;
  width: 100%;
  outline: none !important;
}

.react-tagsinput {
  background: 0 0 !important;
  border: 1px solid #565656 !important;
  border-radius: 7px !important;
  overflow: hidden;
  padding-bottom: 4px !important;
  padding-left: 7px !important;
  padding-top: 11px !important;
}

.react-tagsinput-input {
  color: #fff;
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  width: auto;
}

.react-tagsinput-tag {
  background-color: #e4c07350;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: red !important;
}

.react-tagsinput-remove:hover {
  color: red !important;
}
.small-contained-button-filter {
  background-color: var(--button-background-color) !important;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
  color: var(--portal-theme-primary);
  padding: 0px 16px;
}
.pro {
  color: var(--portal-theme-primary) !important;
}
.basic {
  color: white !important;
}
.colored-last-value {
  color: var(--portal-theme-primary);
}
.heading {
  color: white;
  font-weight: 600;
}

.input-accountability-box .css-fjvvwu-MuiSlider-thumb {
  position: absolute;
  width: 15px;
  height: 15px;
}

.input-accountability-box-performance {
  border: 2px solid var(--sidebars-background-color);
  padding: 13px 20px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
}
.input-accountability-labe-performance {
  /* position: absolute; */
  top: 16px;
  color: #c1c1c1;
  font-size: 12px;
  position: absolute;
  top: -11px;
}
.apexcharts-toolbar,
.apexcharts-legend {
  display: none !important;
}
.fake_lead_status {
  padding: 10px 9px;
  border: 1px solid gray;
  width: 245px;
  border-radius: 6px;
}
.hidden {
  visibility: hidden;
}

.quill-text-editor {
  background-color: #fff;
  color: #000;
}

.quill-text-editor .ql-editor {
  font-size: 16px;
}

.quill-text-editor .quill {
  height: 250px;
  position: relative;
}
.quill-text-editor .ql-snow .ql-tooltip {
  left: 10px !important;
}

.ql-editor a {
  color: #007bff;
}

.ql-syntax {
  color: #000;
}

.icon-arrow-down {
  position: absolute;
  right: 5px;
}
.small-boxss .css-176k6af {
  position: absolute;
  width: 15px;
  height: 15px;
}
.small-boxss .css-lz63i8-MuiSlider-thumb {
  position: absolute;
  width: 14px;
  height: 14px;
}

.esponded-messages .MuiChip-label {
  color: #fff;
}

.chip_div span {
  color: #fff !important;
}

.new_message_text {
  color: var(--portal-theme-primary);
}
.fix-height .MuiPaper-rounded {
  height: 364px;
  overflow-y: auto;
}
.delegate-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-heading {
  font-size: 20px;
  color: var(--portal-theme-primary) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: #9fa1a2 !important;
}
.font-size-14 {
  font-size: 14px;
}
.style {
  border: 2px solid #fff;
  padding: 5px;
  background-color: #1d2537;
  color: var(--portal-theme-primary) !important;
  border-radius: 19px;
  font-size: 11px;
  padding: 2px 5px;
}

.solid-border {
  border: 1px solid #e8e9eb;
  padding: 10px 0px 10px 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.overflow_auto {
  overflow-wrap: anywhere;
}

.glow-reward-feed {
  box-shadow: 0 0 30px var(--portal-theme-primary);
  border: 2px solid var(--portal-theme-primary);
  position: relative;
}

.glow-reward-feed .gif-image img,
.post-detail-box .gif-image img {
  position: absolute;
  height: 88%;
  width: 94%;
}

.upload-image-icon-comment {
  background-color: transparent;
  margin-top: 13px;
}

.preview-comment-image img {
  height: 50px;
  width: 50px;
  display: inline;
  object-fit: cover;
}

.preview-comment-image {
  position: relative;
}

.preview img {
  height: 76px;
  width: 76px;
  border-radius: 5px;
  /* margin-right: 10px; */
  display: inline;
}

.preview-comment-image img {
  height: 50px;
  width: 50px;
  display: inline;
  object-fit: cover;
}

.preview-comment-image span {
  position: absolute;
  right: 3px;
  top: -10px;
  font-size: 13px;
  font-weight: bold;
  height: 20px;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.preview-comment-image span {
  right: -10px;
}

@media (max-width: 540px) {
  .main-icon-conatiner {
    padding-right: 0px;
  }
  .input-field {
    width: 100% !important;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .main-icon-content {
    display: none;
  }
  .line {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .upload-video-update .MuiChip-root svg {
    font-size: 20px;
    margin-left: 3px;
    fill: silver;
  }
  .upload-video-update .MuiChip-root {
    width: 100%;
    cursor: pointer;
    border: hidden;
    background: #222b3e;
  }
}
@media (max-width: 500px) {
  .input-with-profile .input-field {
    font-size: 12px;
    line-height: normal;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.cross-show-all-likes-models {
  position: absolute;
  right: 3px;
  top: 18px;
  height: 25px;
  width: 25px;
  font-size: 14px;
  border-radius: 50%;
  padding-top: 1px;
  background-color: var(--portal-theme-secondary);
  z-index: 1;
}
.broadcastDetail {
  margin-top: 20px;
  margin-bottom: 20px !important;
}
.css-1rysdmt {
  border-color: transparent;
}
.pointer {
  cursor: pointer;
}
.eidit-icon {
  font-size: 18px;
  margin-left: 10px;
  color: var(--portal-theme-primary);
}
.google-button {
  background: #fff !important;
  border: none;
  color: #000;
  font-weight: 500;
}
.remove-google-account {
  position: absolute;
  cursor: pointer;
  top: -5px;
  right: -10px;
  height: 16px;
  width: 16px;
  background: red;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
}
.chart-container {
  height: 234px; /* Adjust the height here */
}

.text-secondary {
  color: var(--text-secondary-color);
}
.box-1 {
  display: flex;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.box-1-color {
  border: 1px solid var(--portal-theme-primary);
  background: var(--portal-theme-primary);
}
.box-2-color {
  border: 1px solid #5ab834;
  background: #5ab834;
}
.box-3-color {
  border: 1px solid #a020f0;
  background: #a020f0;
}
.box-4-color {
  border: 1px solid #0000ff;
  background: #0000ff;
}
.box-5-color {
  border: 1px solid #c70039;
  background: #c70039;
}

.booking-card {
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 15px;
  margin-top: 10px;
  background-color: #1d2537;
}
.booking-card:hover {
  cursor: pointer;
}
.side-barIcon-container {
  width: 363px;
}

.colored-part {
  height: 22px;
  width: 30px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 2px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.booking-report-detail {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: var(--text-primary-color);
  font-size: 15px;
}

.view-content {
  color: var(--portal-theme-primary);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 1198px) {
  .side-barIcon-container {
    width: 133px;
  }
}
@media (min-width: 1399px) {
  .chart-container {
    height: 267px;
  }
}

@media (min-width: 1368px) {
  .chart-container {
    height: 338px;
  }
}
@media (max-width: 1024px) {
  .chart-container {
    height: 227px;
  }
}
@media (max-width: 912px) {
  .chart-container {
    height: 331px;
  }
}

@media (max-width: 820px) {
  .chart-container {
    height: 340px;
  }
}
@media (max-width: 540px) {
  .chart-container {
    height: 260px;
  }
}
@media (max-width: 430px) {
  .chart-container {
    height: 211px;
  }
}
@media (max-width: 400px) {
  .chart-container {
    height: 200px;
  }
}
@media (max-width: 328px) {
  .chart-container {
    height: 157px;
  }
}
@media (max-width: 779px) {
  .for-mobile {
    display: block !important;
  }
  .for-mobile-indication {
    margin-top: 10px;
    flex-wrap: wrap;
    margin-left: 23px;
  }
}

@media (max-width: 540px) {
  .header-logo {
    visibility: hidden;
  }
}

.apexcharts-tooltip {
  color: black !important;
}
.color-text-primary {
  color: var(--text-primary-color);
}

.portal-color {
  color: var(--portal-theme-primary);
}

.MuiInputBase-formControl:hover fieldset {
  border-color: rgba(145, 158, 171, 0.32) !important;
}

.PrivatePickersMonth-root.Mui-selected:hover {
  background-color: var(--portal-theme-primary) !important;
}
.total-score-heading {
  margin-bottom: 0px !important;
}
.tracking-intention {
  background-color: #1d2537;
  padding: 15px 20px;
  border-radius: 5px;
  margin-top: 10px;
}
.audio-container {
  position: relative;
}
.hide-audio {
  position: absolute;
  width: 23px;
  height: 25px;
  background-color: #1d253750;
  display: block;
  z-index: 9999999;
  right: 15px;
  top: 15px;
}

.program-hover:hover {
  color: var(--portal-theme-primary);
  cursor: pointer;
}
.select-menu-checkbox.selected {
  background-color: var(--portal-theme-primary);
  background-color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
}
.select-menu-checkbox {
  border: 1px solid gray;
  border-radius: 3px;
  cursor: pointer;
  height: 18px;
  outline: none;
  position: relative;
  width: 18px;
}
.select-menu-checkbox {
  border: 1px solid gray;
  border-radius: 3px;
  cursor: pointer;
  height: 18px;
  outline: none;
  position: relative;
  width: 18px;
}
.select-menu-checkbox.selected {
  background-color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
}
.select-all-menus {
  cursor: pointer;
  display: flex;
}
.select-menu-checkbox.selected {
  background-color: var(--portal-theme-primary);
  background-color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
  border-color: var(--portal-theme-primary);
}
.select-menu-tick svg {
  fill: #000;
  color: #fff;
  height: 23px;
  left: 0;
  position: absolute;
  top: -4px;
  width: 17px;
}
.menus-search-box,
.sidebar-search-box {
  position: relative;
}
.menus-search-icon,
.sidebar-search-icon {
  color: #d6cfc170;
  left: 10px;
  position: absolute;
  top: 13px;
}
.menus-search-input {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 5px;
  height: 45px;
  padding-left: 34px;
  color: white;
}
.static-textfield {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  cursor: text;
  padding: 8.5px 14px;
  position: relative;
}
.static-textfield-label {
  background-color: #1d1c1d;
  background-color: var(--sidebars-background-color);
  color: #637381;
  font-size: 13px;
  left: 10px;
  position: absolute;
  top: -10px;
}
.static-textfield .field-value {
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown-arrow {
  position: absolute;
  right: 0;
  top: 6px;
}

.table-phone-input .react-tel-input .form-control {
  height: 56px !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-size: 15px !important;
}

.table-phone-input .react-phone-input:focus {
  box-shadow: none !important;
  border: 2px solid var(--portal-theme-primary) !important;
}

.table-phone-input .react-tel-input .flag-dropdown {
  border: none !important;
}
.table-phone-input .react-tel-input .flag-dropdown:hover {
  border: none !important;
}

.table-phone-input .react-tel-input .selected-flag {
  border: none !important;
}

.table-phone-input .react-tel-input .selected-flag .flag {
  left: 12px;
}

.react-phone-input,
.react-tel-input .country-list .search-box {
  width: 100% !important;
  background-color: transparent !important;
  color: #cacaca !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.react-phone-input:focus {
  box-shadow: none !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: rgba(246, 189, 75, 0.16) !important;
}

.react-tel-input .form-control {
  height: 40px;
}

.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown:hover,
.react-tel-input .selected-flag {
  background-color: transparent !important;
  background: transparent !important;
  border: 1px solid rgba(145, 158, 171, 0.32) !important;
}
.country-list {
  max-width: 250px !important;
}
.react-tel-input .country-list .search-box {
  max-width: 94% !important;
  height: 34px;
}
.country-list,
.react-tel-input .country-list .search {
  background-color: var(--sidebars-background-color) !important;
}

.country-list .country:hover {
  background-color: rgba(246, 189, 75, 0.16) !important;
}

.mention-editor {
  position: relative;
  z-index: 99999;
  cursor: text;
}

.mention-editor-box {
  min-height: 50px;
  outline: none;
  max-height: 200px;
  overflow: auto;
  padding-right: 25px;
}

.show-suggestions-names {
  position: absolute;
  top: 23px;
  background-color: var(--background-primary-color);
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 5px 10px -4px rgb(0 3 82 / 24%);
  max-height: 270px;
  overflow: auto;
  min-width: 250px;
  z-index: 1;
  max-width: 100%;
}

.add-comment-box {
  position: relative;
  z-index: 0;
}

.show-suggestions-items {
  width: 100%;
  background-color: var(--background-primary-color);
  outline: none;
  border: none;
  padding: 6px 12px;
  color: #fff;
  align-items: center;
  white-space: normal;
}

.mention-editor-box:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
}

.show-suggestions-items:hover {
  background-color: var(--portal-theme-secondary);
}

.show-suggestions-items .suggestion-name {
  white-space: nowrap;
}

.mentioned-name {
  color: var(--portal-theme-primary);
  padding: 0 3px 2px 3px;
  text-decoration: underline;
}

.post-description .mentioned-name {
  padding: 0;
}

.mention-editor-box .mentioned-name {
  background: var(--background-primary-color);
}

.mentioned-user-info {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-primary-color);
  padding: 16px;
  z-index: 1;
  text-align: center;
  border-radius: 5px;
}

.profile-image-mentioned-user {
  display: inline-block;
}

.show-suggestions-items.selected {
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
}

.main-member-container {
  border: 1px solid var(--sidebars-background-color);
  width: 321px;
  height: 85vh;
  border-radius: 4px;
  background-color: var(--sidebars-background-color);
  padding: 10px;
  flex: none;
}

.comment-editor {
  font-family: Montserrat, sans-serif;
  border-radius: 8px;
  padding: 16.5px 14px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  color: #fff;
  z-index: 1;
}

.comment-editor .mention-editor-box {
  height: 70px;
  overflow: auto;
  padding-right: 0;
}

.reply-editor .mention-editor-box {
  padding-right: 30px;
}

.comment-editor .emoji-picker-button-creat-post {
  bottom: -60px;
  left: 3px;
  top: auto;
}

.picker-empty-div {
  width: 40px;
}

.insert-reply-button {
  position: absolute;
  top: 32px;
  right: 12px;
  cursor: pointer;
  z-index: 2;
}

.main-member-container.selected-card {
  border: 2px solid var(--portal-theme-primary);
}

.lead-stages-popup {
  position: absolute;
  border-radius: 8px;
  background-color: var(--sidebars-background-color);
  outline: 0px;
  overflow: inherit;
  box-shadow: rgba(0, 3, 82, 0.24) 0px 0px 2px 0px,
    rgba(0, 3, 82, 0.24) 0px 20px 40px -4px;
  border: 1px solid var(--portal-theme-primary);
}

.lead-stages-popup-item {
  border-bottom: 1px solid rgba(145, 158, 171, 0.08);
}

.heading-container {
  display: flex;
  justify-content: space-between;
}
.heading-container h4 {
  color: var(--portal-theme-primary);
  text-transform: capitalize;
  margin-bottom: 10px;
}

.heading-container p {
  color: #a9a9a9;
  margin-bottom: 10px;
}

.member-card-container {
  border: 1px solid rgb(242 172 33 / 33%);
  border-radius: 3px;
  padding: 5px;
  width: 100%;
}
.name-container {
  width: 100%;
  margin-top: 3px;
  padding-left: 5px;
}

.lead-starus-member-detail {
  color: #fff;
}

.lead-starus-member-detail h4 {
  color: var(--portal-theme-primary);
  /* text-decoration: underline; */
}

.add-to-cart-button {
  cursor: pointer;
  background: #b4904829;
  padding: 1px 9px;
  color: var(--portal-theme-primary);
  border-radius: 4px;
}

.diary-icon-remove {
  color: red;
  fill: red;
  margin-bottom: 0px;
  cursor: pointer;
  z-index: 9999;
}
.diary-icon-add {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
  margin-bottom: 0px;
  cursor: pointer;
  z-index: 9999;
}
.event_calendar_box .MuiFormControl-root {
  width: 200px !important;
}
.access_type_field {
  width: 200px;
}
.default_consultant_commission_input {
  width: 170px !important;
  color: #fff;
}

.leads-search-input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid var(--portal-theme-primary);
  padding: 6px;
  color: #fff;
}

.lead-status-text {
  border: 1px solid gray;
  padding: 1px 7px;
  border-radius: 10px;
  font-size: 13px;
}

.lead-centre-user-menus {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-image: none;
  position: absolute;
  min-height: 16px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  background-color: var(--sidebars-background-color);
  outline: 0px;
  margin-top: 12px;
  margin-left: 14.4px;
  overflow: inherit;
  box-shadow: rgba(0, 3, 82, 0.24) 0px 0px 2px 0px,
    rgba(0, 3, 82, 0.24) 0px 20px 40px -4px;
  border: 1px solid rgba(145, 158, 171, 0.08);
}

.lead-centre-user-menus:hover,
.lead-stages-popup:hover {
  border: 1px solid var(--portal-theme-primary);
}

.notes-input-card {
  border: 2px solid var(--portal-theme-secondary);
}

.image-container {
  display: none;
}

.image-container img {
  width: 200px;
}

.call-not-allowed svg {
  color: red;
  fill: red;
}

@media only screen and (max-width: 899px) {
  .image-container {
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .image-container img {
    width: 200px;
  }
}

@media only screen and (max-width: 830px) {
  .image-container {
    position: unset;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
  }
  .image-container img {
    width: 200px;
  }
}

.info-image {
  filter: invert(55%) sepia(50%) saturate(1500%) hue-rotate(20deg);
  width: 20px;
  display: inline-block;
  cursor: pointer;
}
.cross-iconss {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--portal-theme-primary);
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.password_radio span {
  color: white;
}

.password_input .radio_button svg,
.password_radio svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.password_input label {
  margin-top: -7px;
}

.password_input .css-1nqe8vp.Mui-focused,
.password_input .css-5dtz1i-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  margin-top: 0px !important;
}

.password_input .radio_button p {
  margin: 10px 0px;
  font-size: 18px;
}

.fw_14 {
  font-size: 14px;
}

.password_input .radio_button span {
  color: white;
}

.replies-button span {
  color: var(--portal-theme-primary) !important;
}

.flag {
  width: 20px;
  height: 13px;
  margin-left: 7px;
}
.image-slider .image-gallery-content .image-gallery-slide .image-gallery-image {
  aspect-ratio: 4/2;
  object-fit: contain;
}

.image-slider .image-gallery-thumbnail .image-gallery-thumbnail-image {
  aspect-ratio: 3/2;
  object-fit: contain;
}
.MuiPaginationItem-ellipsis {
  color: white !important;
}
.bank_detail img {
  text-align: center;
}
.skin-tone-button {
  display: none;
}

.picker-emoji .flex {
  background-color: red !important;
}
.category .flex {
  justify-content: space-between;
}
.diary-icon-remove,
.remove-poll-options {
  color: red;
  fill: red;
  margin-bottom: 2px;
  cursor: pointer;
}

.feed-poll-option {
  border: 1px solid var(--input-border-color);
  padding: 3px;
  border-radius: 5px;
  cursor: pointer;
}

.feed-poll-option .answered {
  background-color: var(--portal-theme-primary);
  border-radius: 4px;
  color: #000;
  font-weight: 500;
}

.feed-poll-option.poll-expired {
  cursor: default;
  opacity: 0.4;
}

.feed-poll-box {
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
}

.view-poll-result {
  border-top: 1px solid var(--portal-theme-secondary);
  cursor: pointer;
  color: var(--portal-theme-primary);
}

.view-poll-result:hover {
  background-color: var(--portal-theme-secondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.feed-poll-option:last-child {
  margin-bottom: 0 !important;
}

.feed-poll-popup-modal {
  min-height: 200px;
  min-width: 480px;
  width: 480px;
}

.poll-result-heading {
  color: var(--portal-theme-primary);
  font-size: 16px;
}

.poll-statement {
  font-size: 12px;
}

.feed-poll-avatars {
  margin-bottom: 10px;
}

.feed-poll-avatars .MuiAvatar-root {
  width: 16px;
  height: 16px;
  font-size: 12px;
}
.poll-expiry-date {
  color: var(--text-secondary-color);
}

.poll-expires {
  padding-left: 10px;
  margin-bottom: 8px;
  color: gray;
  font-size: 13px;
}

.poll-result-card {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.poll-question-item {
  border: 1px solid var(--portal-theme-secondary);
  background-color: #222b3e;
  padding: 10px 12px 8px 12px;
  border-radius: 5px;
  width: 100%;
}

.poll-question-item-post {
  background-color: #222b3e;
  padding: 12px;
  border-radius: 8px;
}

.poll-question-item-post .question-statement,
.poll-question-statement {
  color: var(--portal-theme-primary);
  font-size: 15px;
}

.bg_color_for_alert {
  background: #db0f0f1a !important;
}

.fw-16 {
  font-size: 16px !important;
}

.border-added {
  border: 1px solid var(--portal-theme-primary); /* Example border */
  border-top: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-radius: 8px; /* Optional: for rounded corners */
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.allow-multiple-options .MuiCheckbox-colorPrimary {
  padding: 4px;
  margin-left: 4px;
}

.allow-multiple-options .MuiFormControlLabel-label,
.poll-question-item-post .MuiFormControlLabel-label {
  font-size: 14px !important;
}

.poll-question-item-post .MuiCheckbox-colorPrimary svg,
.poll-question-item-post .MuiRadio-colorPrimary svg {
  width: 22px !important;
  height: 22px !important;
}

.poll-progress-bar {
  flex: 1;
  height: 7px;
  background-color: var(--portal-theme-secondary);
  border-radius: 15px;
}

.poll-progress-bar .selected-progress-bar {
  background-color: var(--portal-theme-primary);
  height: 100%;
  border-radius: 15px;
}

.poll-progress-bar .selected-progress-bar.poll-expired {
  opacity: 0.4;
}

.download-icon-new {
  width: 40px;
}
.color-white {
  color: #fff !important;
}

.send-message-button {
  background: transparent;
  margin: 0px;
  border-radius: 50%;
  padding: 0px;
  height: fit-content;
  border: transparent;
}
.del_img_icon {
  position: relative;
  display: inline-block;
}

.del_img_icon span {
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  width: 15px;
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  z-index: 1;
  cursor: pointer;
}

.card-body.notes-input .normal-text {
  color: var(--text-primary-color);
}

.unlock-date-note {
  background-color: var(--sidebars-background-color);
  border-left: 4px solid var(--portal-theme-primary);
  border-radius: 7px;
  font-style: italic;
  padding: 10px;
}

.fs-13 {
  font-size: 13px;
}

.incoming-message-note-card {
  background-color: #e4e6eb !important;
}

.incoming-message-note-card .normal-text,
.incoming-message-note-card .updated-date span {
  color: #000 !important;
}

.incoming-message-note-card .action-info-name {
  color: var(--sidebars-background-color);
  font-weight: 600;
}

.send-sms-to-member svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

.action-box-style {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.action-box-style svg {
  color: #fff;
  fill: #fff;
}

.action-box-style.whatsapp-style {
  background-color: #4dc95b;
}
.action-box-style.chat-style {
  background-color: var(--portal-theme-primary);
  /* color: #6a4c37; */
}
.action-box-style.sms-style {
  background-color: #1971b6;
}

.story-user-box {
  position: absolute;
  top: 10px;
  left: 10px;
}

.story-user-box .MuiAvatar-circular {
  width: 30px;
  height: 30px;
  border: 2px solid rgb(255, 216, 137);
}

.story-user-name-box:first-child {
  left: 0;
  right: 0;
  background-color: #fff;
  bottom: 0;
  color: #000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 50px;
}

.story-user-name-box {
  position: absolute;
  bottom: 4px;
  left: 10px;
  right: 10px;
  z-index: 2;
  color: white;
}

.win-story-card .user-profile-image {
  width: 35px;
  height: 35px;
  border: 2px solid rgb(255, 216, 137);
  position: absolute;
  top: 20px;
  left: 10px;
}

.carousel-control-prev {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: inline-block !important;
  width: 20px;
  height: 20px;
  left: 10px;
}

.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  right: 10px;
  display: inline-block !important;
  width: 20px;
  height: 20px;
}

.carousel-control-prev .slider-icon,
.carousel-control-next .slider-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--portal-theme-primary);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.new-community-page .win-story-card {
  width: 120px !important;
}

.win-story-card {
  width: 180px;
  flex: none;
}

.mission-levels-schedule-tab .schedule-click {
  cursor: pointer;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
}

.win-story-card .schadule-card {
  height: 230px;
  border-radius: 10px;
}

.story-card {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.win-story-card .schedule-click {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
}

.new-community-page .win-story-card .schadule-card {
  height: 165px !important;
}

.new-community-page .win-story-card .story-user-name {
  color: white;
  font-size: 13px;
}

.story-feed-description {
  position: absolute;
  top: 50px;
  left: 10px;
  color: #ffffffcf;
  line-height: 1.4;
  font-size: 12px;
}

.create-win-post-card-box .story-user-name {
  color: #000 !important;
  text-align: center;
  bottom: 6px;
  position: absolute;
  left: 27px;
}

.create-story-circule-border {
  display: flex;
  justify-content: center;
  background-color: #fff;
  bottom: 30px;
  position: absolute;
  left: 42px;
  padding: 3px;
  border-radius: 50%;
}

.create-story-circule {
  border-radius: 50%;
  background-color: var(--portal-theme-primary);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-story-circule svg {
  color: #000;
  fill: #000;
}

.post-creator.user-info-card .like-profile-image {
  border-radius: 50%;
  padding: 1px;
}

.create-post-title-form .mention-editor {
  background-color: #222b3e;
  padding: 10px;
  border-radius: 10px;
}

.create-post-title-form .mention-editor .mention-editor-box {
  min-height: 80px !important;
}

.create-post-title-form .search-input input {
  background-color: #222b3e !important;
  border-radius: 2px;
  margin-bottom: 0 !important;
}

.create-post-title-form .creator-name .MuiSelect-select {
  padding: 3px 10px !important;
}

.select-multiple-level-controls {
  min-width: 150px !important;
}

.select-multiple-level-controls label {
  line-height: 0.5rem !important;
}

.MuiPaper-root .MuiPickersDay-root {
  background-color: initial !important;
  color: #fff !important;
}

.MuiPaper-root .MuiPickersDay-root.Mui-selected {
  background-color: var(--portal-theme-primary) !important;
}

.MuiPaper-root .MuiPickersDay-root:hover {
  background-color: var(--portal-theme-primary) !important;
}

.color-white {
  color: white !important;
}

.color-white p {
  color: white !important;
}
.custom-lable {
  font-size: 13px;
  margin-top: 8px;
  display: inline-block;
}
.question-text p {
  color: white !important;
}
.action-main-container {
  cursor: pointer;
  /* background: #2b2929; */
  padding: 7px 11px;
  border-radius: 6px;
}

.bg-mission {
  /* background-color: #1c1c1c; */
  border-radius: 15px;
}
.bg-info-of-mission {
  /* background-color: #202023; */
  padding: 10px;
  border-radius: 15px;
  min-width: 32%;
}

.bg-info-of-mission-2 {
  border-radius: 10px !important;
  top: 61px;
  left: 57px;
}

.transparent-background {
  padding: 16px 10px;

  border-radius: 8px !important;
  background-color: #222b3e;
  border-radius: 10px;
}

.info-of-mission-text {
  font-weight: 500;
  text-transform: capitalize;
}

.unlock-popup-cross-icon {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: 17px;
  top: 5px;
  text-align: center;
  font-size: 19px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99999;
}

.question-text {
  color: var(--portal-theme-primary);
}

.arrow_box {
  background-color: #222b3e;
  color: white;
  padding: 10px;
  z-index: 9999999;
  position: relative;
}

/* .mission-levels start*/
.mission-levels .mission-icon {
  height: 30px;
}
.mission-levels .next-arrow-icon {
  height: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.mission-levels h5 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}

.mission-levels h2 {
  color: #fff;
  margin-bottom: 0;
  margin-right: 10px;
  font-weight: 600;
  color: var(--portal-theme-primary);
}

.mission-levels .completed-missions {
  display: flex;
  justify-content: end;
  align-items: center;
}

.mission-levels .completed-missions span {
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}

.mission-levels .completed-missions:hover {
  text-decoration: underline;
}

.challenge-video-title {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.challenge-video-title h5 {
  color: #fff;
  margin-left: 0;
}

.challenge-video-image {
  border-radius: 10px;
}

.challenge-video-card .level-icons img {
  height: 35px;
  margin-right: 4px;
  max-width: 35px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
}

.challenge-video-card .level-icons h2 {
  font-size: 25px;
}

.challenge-lock-description {
  display: flex;
  align-items: flex-end;
}

.challenge-lock-description p {
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.challenge-lock-description img {
  height: 25px;
}

.mc-tab-description h3 {
  margin-top: 16px;
}

.mission-levels-schedule-tab .card {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.mission-levels-schedule-tab h6 {
  margin-bottom: 5px;
}

.mission-levels-schedule-tab .schedule-click {
  cursor: pointer;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.mission-levels-schedule-tab .locked-component + .schedule-click {
  opacity: 0.3;
  cursor: initial;
}

.mission-levels-schedule-tab .locked-component {
  position: absolute;
  background-color: #80808036;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.mission-levels-schedule-tab .lock-thumbnail-icon {
  height: 20px;
  cursor: pointer;
}

.challenge-video-days-tabs .locked-component img {
  height: auto !important;
  margin-top: 130px;
}

.challenge-video-days-tabs .locked-component {
  align-items: flex-start;
}

.mc-day-input {
  border: 1px solid var(--input-border-color);
  padding: 0px 16px;
  border-radius: 12px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.mc-day-input h6,
.mc-day-input span {
  font-size: 14px;
  margin-bottom: 0;
}

.mc-day-input.disabled h6,
.mc-day-input.disabled span {
  color: gray;
}

.mc-day-input img {
  height: 14px;
  margin-left: 5px;
}

.mc-day-input .tiny-text {
  font-size: 11px;
  color: #9e9c9c;
}

.day-video-card {
  padding: 15px 20px;
  background-color: var(--sidebars-background-color);
  border-radius: 10px;
  position: relative;
}

.day-video-card p {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.day-video-card .unlocked,
.day-video-card .locked {
  margin-bottom: 5px;
}

.day-video-card .unlocked span {
  color: gray;
  font-size: 14px;
  margin-left: 5px;
}

.day-video-card .locked span {
  font-size: 14px;
  margin-left: 5px;
}

.mc-video-button-contained {
  background: linear-gradient(180deg, #ebd7aa 0%, #f0c26a 100%);
  width: 88%;
  outline: none;
  border: none;
  padding: 8px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 14px;
  position: absolute;
  bottom: 20px;
}

.mc-video-button-outlined {
  width: 88%;
  outline: none;
  border: none;
  padding: 8px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 10px;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--portal-theme-primary);
  border: 1px solid var(--portal-theme-primary);
  font-size: 14px;
  position: absolute;
  bottom: 20px;
}

.mc-button-contained,
.challenge-video-days-questions .question-buttons button {
  background: linear-gradient(180deg, #ebd7aa 0%, #f0c26a 100%);
  color: #000;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  white-space: nowrap;
  padding: 8px 20px;
  border: none;
  outline: none;
  text-transform: uppercase;
}

.mc-button-contained:disabled,
.heading-disabled {
  opacity: 0.2;
}

.challenge-video-days-questions .show-btn-disabled {
  display: none;
}

.mc-button-contained.floating-button {
  position: fixed;
  bottom: 50px;
  right: 30px;
  display: inline;
  z-index: 999999999;
}

.mc-button-outlined,
.challenge-video-days-questions .small-contained-button {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 10px;
  white-space: nowrap;
  padding: 8px 20px;
  border: 1px solid var(--input-border-color);
  outline: none;
  text-transform: uppercase;
}

.show-lock-icon-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
}

.day-video-card .thumbnail-icon {
  opacity: 0.2;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
}

.day-video-card .video-description {
  margin-bottom: 50px !important;
}

.unlock-video {
  color: #fff;
  position: relative;
}

.unlock-video p {
  color: gray;
}

.unlock-popup-cross-icon {
  height: 30px;
  width: 30px;
  background-color: var(--portal-theme-secondary);
  position: absolute;
  right: -10px;
  top: -10px;
  text-align: center;
  font-size: 19px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99999;
}

.challenge-video-days-tabs .mui-custom-tab-item:hover,
.challenge-video-detail-tabs .mui-custom-tab-item:hover,
.challenge-video-days-title h2,
.challenge-video-days-title svg,
.challenge-video-days-questions svg,
.challenge-video-detail-title h2,
.challenge-video-days-questions .small-contained-button,
.challenge-video-days-questions a,
.congratulation-message span,
.challenge-video-days-questions .star > button,
.unlock-popup-cross-icon,
.day-video-card .locked span,
.mc-day-input span,
.coins-attracted h5,
.day-video-card h6,
.mission-levels-schedule-tab h6,
.schedule-heading h2,
.mission-levels-overview-tab h3,
.allowed-actions-box h2,
.schedule_completed_description h2,
.mission-level-card h3,
.challenge-video-detail-title svg {
  color: var(--portal-theme-primary);
}

.challenge-video-days-tabs .Mui-selected.mui-custom-tab-item,
.challenge-video-detail-tabs .Mui-selected.mui-custom-tab-item {
  background: transparent;
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-primary);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
}

.challenge-video-days-tabs .mui-custom-tab-item:hover {
  background-color: transparent;
  color: var(--portal-theme-primary);
}

.challenge-video-days-tabs .MuiTabs-indicator {
  background-color: var(--portal-theme-primary);
}

.challenge-video-days-questions .card {
  border: 1px solid var(--input-border-color);
}

.challenge-video-days-questions .star > button {
  border: 1px solid var(--portal-theme-primary);
}

.questions-top-section {
  padding: 0 12px;
  color: #fff;
  position: relative;
}

.challenge-video-days-questions .self-button {
  display: none;
}

.challenge-video-days-questions .question-buttons {
  display: block !important;
  text-align: end;
}

.congratulation-message {
  text-align: center;
}

.congratulation-message img {
  width: 300px;
  display: inline;
}

.congratulation-message p {
  color: #fff;
  margin-bottom: 5px;
}

.challenge-video-days-questions .row.justify-content-center.pb-5 {
  padding-bottom: 10px !important;
}

.coins-attracted {
  padding-left: 10px;
}

.coins-attracted h5 {
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 0;
}

.coins-attracted h6 {
  font-size: 15px;
  margin-bottom: 0;
}

.schedule-heading {
  /* border: 1px solid var(--portal-theme-primary); */
  /* border-width: 1px 1px 0 1px; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  text-align: center;
  background-color: var(--sidebars-background-color);
  font-size: 18px;
}

.mission-levels-overview-tab {
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 10px;
  overflow: auto;
  font-size: 14px;
}

.mission-levels-overview-tab.not-started {
  opacity: 0.1;
}

.mission-levels-overview-tab p {
  margin-bottom: 5px !important;
}

.mission-levels-overview-tab h3 {
  margin-top: 1rem;
}
.mission-levels-overview-tab ul {
  margin-bottom: 0 !important;
}

.allowed-actions-box {
  background-color: var(--sidebars-background-color);
  margin-top: 16px;
  border-radius: 10px;
  padding: 16px;
  height: 100%;
}

.inside-accordion-questions .MuiAccordion-gutters {
  border: 1px solid var(--portal-theme-secondary);
}

.close-popup {
  position: absolute;
  right: -5px;
  top: -5px;
  height: 35px;
  width: 35px;
  background-color: var(--portal-theme-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--portal-theme-primary);
  border-radius: 50%;
  cursor: pointer;
}

.close_plan_popup_description {
  color: #fff;
}

.total-earning-history {
  color: var(--portal-theme-primary);
}

.earning-description {
  color: lightgray;
  font-size: 15px;
}

.theme-color {
  color: var(--portal-theme-primary);
}
.mission-level-heading h2,
.mission-level-heading img {
  display: inline !important;
}

.mission-level-heading h2 {
  margin-bottom: 16px !important;
}

.mission-level-heading img {
  height: 30px;
  width: 30px;
  margin-top: -10px !important;
}

.required-asterisk {
  color: red;
  font-size: 18px;
}

.save-icon {
  color: var(--portal-theme-primary);
  font-size: 18px;
}

.action-checkbox {
  height: 16px;
  width: 16px;
  border: 2px solid var(--portal-theme-primary);
  border-radius: 3px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-checkbox-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  background: #222b3e;
  padding: 7px 11px;
  border-radius: 6px;
}
.action-checkbox-box-new {
  display: flex;
  cursor: pointer;
  align-items: center;
  background: #222b3e;
  padding: 7px 11px;
  border-radius: 6px;
  justify-content: space-between;
}

.action-checkbox-box-new.disabled {
  opacity: 0.3;
}

.action-checkbox-box.disabled {
  opacity: 0.3;
}

.action-checkbox.checked {
  background-color: var(--portal-theme-primary);
}

.action-checkbox.checked i {
  color: #000;
}

.checkbox-label {
  font-size: 15px;
}

.challenge-video-days-questions .questions_top_description {
  color: #fff;
}

.challenge-video-days-questions-modal {
  min-height: 200px;
  width: 510px;
}

.mission-level-card {
  border-radius: 16px;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.mission-level-card > img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.start-mission-note {
  background-color: #222b3e;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}
.start-mission-note {
  background-color: #222b3e;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
}

.unlock-date-note {
  background-color: #222b3e;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  margin-bottom: 0px;
  margin-top: 5px;
}

.level-not-match {
  background-color: #222b3e;
  border-left: 4px solid var(--portal-theme-primary);
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
}

.start-mission-note .note-label {
  font-weight: bold;
  color: var(--portal-theme-primary);
}

.mission-days {
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--portal-theme-primary);
  color: #000;
  padding: 2px 20px;
  font-weight: 500;
  border-top-right-radius: 16px;
}

.copy-app-url-box {
  position: absolute;
  right: 13px;
  top: 15px;
  color: #000;
  font-weight: 500;
  border-top-left-radius: 16px;
}

.copy-app-url-box svg {
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}

@keyframes borderPulse {
  0% {
    border-color: var(--portal-theme-primary);
    box-shadow: 0 0 2px var(--portal-theme-primary);
  }
  50% {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 5px var(--portal-theme-primary);
  }
  100% {
    border-color: var(--portal-theme-primary);
    box-shadow: 0 0 2px var(--portal-theme-primary);
  }
}

.members-leader-board {
  color: #fff;
  font-size: 14px;
  max-height: 430px;
  overflow: auto;
}

.members-leader-board hr {
  margin-top: 10px;
  margin-bottom: 0px;
}

.members-leader-board .view-all-button {
  color: var(--portal-theme-primary);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.leader-board-loader {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-heading-leader-boeard {
  margin-bottom: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid gray;
}

.challenge-heading {
  font-size: 18px;
}

.main-card-container {
  background: #222b3e;
  border-radius: 8px;
  padding: 5px 10px;
}

.indication-text {
  align-items: center;
  background: #222b3e;
  border: 1px solid var(--portal-theme-secondary);
  border-radius: 21px;
  color: #b7b7b7;
  display: flex;
  font-size: 12px;
  padding: 4px 12px;
}

.info-of-mission-text {
  text-transform: uppercase;
}

.mission_dashboard_description h3 {
  color: var(--portal-theme-primary);
}

.user-action-detail {
  color: gray;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

.user-action-detail span {
  color: var(--portal-theme-primary);
}

.view-review-feed span {
  color: var(--portal-theme-primary);
  cursor: pointer;
  text-decoration: underline;
}

.review-reason-popup h3 {
  color: var(--portal-theme-primary);
  font-weight: 500;
  margin-bottom: 5px !important;
}

.mission_duration_label_in_report {
  font-size: "1rem";
  font-weight: 400;
  line-height: 1.5;
}

.review-comment-message {
  color: #fff;
}

.missions-list-card-title {
  color: var(--portal-theme-primary);
  font-size: 25px;
}

.title-source-journey {
  color: var(--portal-theme-primary);
}

.header.bg-mission {
  font-size: 15px;
}

.quests-leader-board-image {
  width: 50px;
  object-fit: cover;
  height: auto;
  border-radius: 5px;
}

.quest-leader-board-item {
  background-color: #222b3e;
  padding: 5px 3px;
  border-radius: 2px;
  cursor: pointer;
}

.pods-cards-shadow .programme-card-desc {
  color: #fff;
}

.content-updates,
.sidebar-updates {
  max-height: 80vh;
  overflow: auto;
}

.sidebar-updates .nav {
  height: 80vh;
  border-radius: 5px;
}

.portal-updates-container {
  background-color: var(--background-primary-color);
}

.portal-updates-container .nav,
.portal-updates-container .section-card-item {
  background-color: var(--sidebars-background-color) !important;
}

.portal-updates-container .section-card-item p {
  margin-bottom: 5px;
}

.portal-updates-container .section-card-item .update-title {
  color: var(--portal-theme-primary);
  font-size: 28px;
}

.portal-updates-container .nav-link {
  color: #fff;
}

.portal-updates-container .section-card-item .updates-title {
  color: var(--portal-theme-primary);
  font-style: italic;
  text-decoration: underline;
  font-size: 25px;
}

.portal-updates-container .nav-link.active {
  color: var(--portal-theme-primary);
  background-color: var(--portal-theme-secondary);
}

.page-heading h2 {
  color: var(--portal-theme-primary);
}

.custom-popover-menu {
  z-index: 99999;
}

.SnackbarContainer-bottom {
  z-index: 999999 !important;
}

@media (max-width: 768px) {
  .content-updates {
    height: 50vh;
  }
  .sidebar-updates {
    height: auto;
    max-height: 33vh;
  }
}
.three_dot_label {
  font-size: 15px;
}

.start-mission-note-new {
  background-color: #ff333322;
  border-left: 4px solid #ff3333;
  padding: 10px;
  font-style: italic;
  border-radius: 7px;
  position: relative;
}
.start-mission-note-new .note-label {
  font-weight: bold;
  color: var(--portal-theme-primary);
}
.warning-icon {
  color: #ff3333;
}

.all-users-mention {
  width: 28px;
  height: 28px;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 50%;
  background-color: var(--portal-theme-secondary);
  text-align: center;
}

.all-users-mention svg {
  font-size: 16px;
  color: var(--portal-theme-primary);
  fill: var(--portal-theme-primary);
}
